export const maskTelefone = (telefone?: string): string => {
  if (!telefone) {
    return ''
  }

  let novoTelefone = telefone.replace(/\D/g, '')
  novoTelefone = novoTelefone.replace(/^0/, '')
  if (telefone.length === 13) {
    novoTelefone = novoTelefone.replace(
      /^(\d\d)(\d{2})(\d{5})(\d{4}).*/,
      '+$1 ($2) $3-$4',
    )
  } else if (telefone.length === 12) {
    novoTelefone = novoTelefone.replace(
      /^(\d\d)(\d{2})(\d{4})(\d{4}).*/,
      '+$1 ($2) $3-$4',
    )
  } else {
    novoTelefone = novoTelefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  }
  return novoTelefone
}
