import 'maxscalla-lib/dist/styles'

import { ReactElement, lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import registrarModulosChartjs from '../utils/registrar-modulos-chartjs'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistedStore } from 'storeRedux'

import { ClientesHistoricoCompraProvider } from 'contexts/clientesHistoricoCompraContext'
import ProviderContextLogin from '../contexts/loginContext'
import { MainProvider } from '../contexts/mainContext'

import { Spinner, Container } from '../components'
import { ThemeProvider } from 'styled-components'
import PrivateRoute from './PrivateRoute'
import { colors } from 'maxscalla-lib'

const Login = lazy(() => import('../pages/Login'))

/**
 * Demonstrativos
 */

const ClienteHistoricoCompra = lazy(
  () => import('../pages/Demonstrativos/ClienteHistoricoCompra'),
)

const TopVendas = lazy(() => import('../pages/Demonstrativos/TopVendas'))

const ComparativoDeVendas = lazy(
  () => import('../pages/Demonstrativos/ComparativoDeVendas'),
)

const DemonstrativosCartoes = lazy(
  () => import('../pages/Demonstrativos/Cartoes'),
)

const MovimentoVendas = lazy(
  () => import('../pages/Demonstrativos/DashboardsComerciais'),
)

/**
 * Financeiro
 */

const FinanceiroCartoes = lazy(() => import('../pages/Financeiro/Cartoes'))

const DuplicatasAReceber = lazy(
  () => import('../pages/Financeiro/DuplicatasAReceber'),
)

const ChequesRecebidos = lazy(
  () => import('../pages/Financeiro/ChequesRecebidos'),
)

const ContasAPagar = lazy(() => import('../pages/Financeiro/ContasAPagar'))

/**
 * Caixas
 */

const CaixasDetalhamento = lazy(() => import('../pages/Caixas/Detalhamento'))

const CaixaOperacoesNaoRecebidas = lazy(
  () => import('../pages/Caixas/OperacoesNaoRecebidas'),
)
const FechamentosDeCaixas = lazy(
  () => import('../pages/Caixas/FechamentosDeCaixa'),
)

const AbatimentoDeContas = lazy(
  () => import('../pages/Caixas/AbatimentoDeContas'),
)

const LotesDeRecebimento = lazy(
  () => import('../pages/Caixas/LotesDeRecebimento'),
)

/**
 * Comercial
 */
const OperacoesComerciais = lazy(
  () => import('../pages/Comercial/OperacoesComerciais'),
)

const FechamentosDeContas = lazy(
  () => import('../pages/Comercial/FechamentosDeContas'),
)

const OrcamentosNaoConvertidos = lazy(
  () => import('../pages/Comercial/OrcamentosNaoConvertidos'),
)

const FollowupsDeOperacoes = lazy(
  () => import('../pages/Comercial/FollowupsDeOperacoes'),
)

/**
 * Expedicao
 */

const TransferenciasDeMercadorias = lazy(
  () => import('../pages/Expedicao/TransferenciasDeMercadorias'),
)
const GestaoDeEntregas = lazy(
  () => import('../pages/Expedicao/GestaoDeEntregas'),
)

/**
 * Compras
 */

const PedidosDeCompra = lazy(() => import('../pages/Compras/PedidosDeCompra'))

const NotasDeEntrada = lazy(() => import('../pages/Compras/NotasDeEntrada'))

const CotacoesDeCompras = lazy(
  () => import('../pages/Compras/CotacoesDeCompras'),
)
/**
 * Produtos
 */
const CadastroDeProdutos = lazy(
  () => import('../pages/Produtos/CadastroDeProdutos'),
)

const EmbalagensDeProdutos = lazy(
  () => import('../pages/Produtos/EmbalagensDeProdutos'),
)

const GruposDeProdutos = lazy(
  () => import('../pages/Produtos/GruposDeProdutos'),
)

const DepartamentosDeProdutos = lazy(
  () => import('../pages/Produtos/DepartamentosDeProdutos'),
)

const CategoriasDeProdutos = lazy(
  () => import('../pages/Produtos/CategoriasDeProdutos'),
)

const Fabricantes = lazy(() => import('../pages/Produtos/Fabricantes'))

/**
 * Fiscal
 */
const NotasFiscais = lazy(() => import('../pages/Fiscal/NotasFiscais'))

const CuponsFiscaisEletronicosEmitidos = lazy(
  () => import('../pages/Fiscal/CuponsFiscaisEletronicosEmitidos'),
)

/**
 * Cadastros
 */
const BandeirasDeCartoes = lazy(
  () => import('../pages/Cadastros/BandeirasDeCartoes'),
)

const ContasDeMovimentacaoFinanceira = lazy(
  () => import('../pages/Cadastros/ContasDeMovimentacaoFinanceira'),
)

const ClientesConstantes = lazy(
  () => import('../pages/Cadastros/ClientesConstantes'),
)

const ClientesEsporadicos = lazy(
  () => import('../pages/Cadastros/ClientesEsporadicos'),
)

const UnidadeDeNegocios = lazy(
  () => import('../pages/Cadastros/UnidadeDeNegocios'),
)

const CentroDeCustos = lazy(() => import('../pages/Cadastros/CentroDeCustos'))

const Representantes = lazy(() => import('../pages/Cadastros/Representantes'))

const Transportadoras = lazy(() => import('../pages/Cadastros/Transportadoras'))

const Fornecedores = lazy(() => import('../pages/Cadastros/Fornecedores'))

const ParceirosIndicantes = lazy(
  () => import('../pages/Cadastros/ParceirosIndicantes'),
)

const Marketplaces = lazy(() => import('../pages/Cadastros/Marketplaces'))

const RamosDeAtividade = lazy(
  () => import('../pages/Cadastros/RamosDeAtividade'),
)

const CondicoesDePagamento = lazy(
  () => import('../pages/Cadastros/CondicoesDePagamento'),
)

const AdministradoraDeCartoes = lazy(
  () => import('../pages/Cadastros/AdministradoraDeCartoes'),
)

const ClassificacaoReceitasDespesas = lazy(
  () => import('../pages/Cadastros/ClassificacaoReceitasDespesas'),
)

const PlanoDeContasDRE = lazy(
  () => import('../pages/Cadastros/PlanoDeContasDRE'),
)

const TabelaDespesas = lazy(() => import('../pages/Cadastros/TabelaDespesas'))

const TabelaReceitas = lazy(() => import('../pages/Cadastros/TabelaReceitas'))

const Regioes = lazy(() => import('../pages/Cadastros/Regioes'))

const Cidades = lazy(() => import('../pages/Cadastros/Cidades'))

const Paises = lazy(() => import('../pages/Cadastros/Paises'))

const CadastroDeDolares = lazy(
  () => import('../pages/Cadastros/CadastroDeDolares'),
)

const Feriados = lazy(() => import('../pages/Cadastros/Feriados'))
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-

const Router = (): ReactElement => {
  useEffect(() => {
    registrarModulosChartjs()
  }, [])

  useEffect(() => {
    const isGestor = document.location.href.includes('gestor')

    const favIcon = document.querySelector(
      "link[rel='shortcut icon']",
    ) as unknown as {
      href: string
    }

    if (isGestor) {
      favIcon.href = '/images/favicon/gestor.ico'

      document.title = 'CS Gestor'
    } else {
      favIcon.href = '/images/favicon/cs.ico'

      document.title = 'CS'
    }
  }, [])

  return (
    <ThemeProvider theme={colors}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <BrowserRouter>
            <Suspense
              fallback={
                <Spinner style={{ top: '300px', display: 'inline-block' }} />
              }
            >
              <MainProvider>
                <ClientesHistoricoCompraProvider>
                  <ProviderContextLogin>
                    <Routes>
                      <Route path="/" element={<Login />} />

                      <Route element={<Container />}>
                        <Route element={<PrivateRoute />}>
                          {/*--|Demonstrativos|*/}

                          <Route
                            path="/demonstrativos/top-vendas"
                            element={<TopVendas />}
                          />
                          <Route
                            path="/demonstrativos/comparativo-de-vendas"
                            element={<ComparativoDeVendas />}
                          />
                          <Route
                            path="/demonstrativos/cliente-historico-compras"
                            element={<ClienteHistoricoCompra />}
                          />
                          <Route
                            path="/demonstrativos/cartoes"
                            element={<DemonstrativosCartoes />}
                          />
                          <Route
                            path="/demonstrativos/comercial-dashboards"
                            element={<MovimentoVendas />}
                          />

                          {/*--|Caixas|------------------------------------------*/}
                          <Route
                            path="/caixas/detalhamento"
                            element={<CaixasDetalhamento />}
                          />

                          <Route
                            path="/caixas/operacoes-nao-recebidas"
                            element={<CaixaOperacoesNaoRecebidas />}
                          />
                          <Route
                            path="/caixas/fechamentos-de-caixa"
                            element={<FechamentosDeCaixas />}
                          />
                          <Route
                            path="/caixas/abatimento-de-contas"
                            element={<AbatimentoDeContas />}
                          />
                          <Route
                            path="/caixas/lotes-de-recebimento"
                            element={<LotesDeRecebimento />}
                          />

                          {/*--|Comercial|--------------------------------------*/}
                          <Route
                            path="/comercial/operacoes"
                            element={<OperacoesComerciais />}
                          />
                          <Route
                            path="/comercial/fechamentos-de-contas"
                            element={<FechamentosDeContas />}
                          />
                          {/*----------------------------------------------------*/}

                          {/*--|Expedição|--------------------------------------*/}
                          <Route
                            path="/expedicao/transferencias-de-mercadorias"
                            element={<TransferenciasDeMercadorias />}
                          />
                          <Route
                            path="/expedicao/gestao-de-entregas"
                            element={<GestaoDeEntregas />}
                          />

                          {/*----------------------------------------------------*/}

                          {/*--|Compras|--------------------------------------*/}
                          <Route
                            path="/compras/pedidos-de-compra"
                            element={<PedidosDeCompra />}
                          />
                          <Route
                            path="/compras/notas-de-entrada"
                            element={<NotasDeEntrada />}
                          />
                          <Route
                            path="/compras/cotacoes-de-compras"
                            element={<CotacoesDeCompras />}
                          />
                          {/*----------------------------------------------------*/}
                          {/*--|Financeiro|--------------------------------------*/}

                          <Route
                            path="/financeiro/cartoes"
                            element={<FinanceiroCartoes />}
                          />

                          <Route
                            path="/financeiro/duplicatas-a-receber"
                            element={<DuplicatasAReceber />}
                          />
                          <Route
                            path="/financeiro/cheques-recebidos"
                            element={<ChequesRecebidos />}
                          />
                          <Route
                            path="/financeiro/contas-a-pagar"
                            element={<ContasAPagar />}
                          />
                          <Route
                            path="/caixas/fechamentos-de-caixa"
                            element={<FechamentosDeCaixas />}
                          />

                          {/*----------------------------------------------------*/}

                          {/*--|Comercial|--------------------------------------*/}
                          <Route
                            path="/comercial/operacoes"
                            element={<OperacoesComerciais />}
                          />
                          <Route
                            path="/comercial/fechamentos-de-contas"
                            element={<FechamentosDeContas />}
                          />
                          <Route
                            path="/comercial/orcamentos-nao-convertidos"
                            element={<OrcamentosNaoConvertidos />}
                          />
                          <Route
                            path="/comercial/followups-de-operacoes"
                            element={<FollowupsDeOperacoes />}
                          />

                          {/*----------------------------------------------------*/}

                          {/*--|Cadastros|--------------------------------------*/}

                          <Route
                            path="/cadastro/bandeiras-de-cartoes"
                            element={<BandeirasDeCartoes />}
                          />
                          <Route
                            path="/cadastro/contas-de-movimentacao-financeira"
                            element={<ContasDeMovimentacaoFinanceira />}
                          />
                          {/*----------------------------------------------------*/}

                          {/*--|Produtos|----------------------------------------*/}
                          <Route
                            path="/produtos/cadastro-de-produtos"
                            element={<CadastroDeProdutos />}
                          />
                          <Route
                            path="/produtos/embalagens-de-produtos"
                            element={<EmbalagensDeProdutos />}
                          />
                          <Route
                            path="/produtos/grupos-de-produtos"
                            element={<GruposDeProdutos />}
                          />
                          <Route
                            path="/produtos/departamentos-de-produtos"
                            element={<DepartamentosDeProdutos />}
                          />
                          <Route
                            path="/produtos/categorias-de-produtos"
                            element={<CategoriasDeProdutos />}
                          />
                          <Route
                            path="/produtos/fabricantes"
                            element={<Fabricantes />}
                          />
                          {/*----------------------------------------------------*/}

                          {/*--|Fiscal|------------------------------------------*/}
                          <Route
                            path="/fiscal/cupons-fiscais-eletronicos-emitidos"
                            element={<CuponsFiscaisEletronicosEmitidos />}
                          />
                          <Route
                            path="/fiscal/notas-fiscais"
                            element={<NotasFiscais />}
                          />
                          {/*----------------------------------------------------*/}

                          {/*--|Cadastro|----------------------------------------*/}
                          <Route
                            path="/cadastro/clientes-constantes"
                            element={<ClientesConstantes />}
                          />
                          <Route
                            path="/cadastro/clientes-esporadicos"
                            element={<ClientesEsporadicos />}
                          />
                          <Route
                            path="/cadastro/unidade-de-negocios"
                            element={<UnidadeDeNegocios />}
                          />
                          <Route
                            path="/cadastro/centro-de-custos"
                            element={<CentroDeCustos />}
                          />
                          <Route
                            path="/cadastro/representantes"
                            element={<Representantes />}
                          />
                          <Route
                            path="/cadastro/transportadoras"
                            element={<Transportadoras />}
                          />
                          <Route
                            path="/cadastro/fornecedores"
                            element={<Fornecedores />}
                          />
                          <Route
                            path="/cadastro/parceiros-indicantes"
                            element={<ParceirosIndicantes />}
                          />
                          <Route
                            path="/cadastro/marketplaces"
                            element={<Marketplaces />}
                          />
                          <Route
                            path="/cadastro/ramos-de-atividade"
                            element={<RamosDeAtividade />}
                          />
                          <Route
                            path="/cadastro/condicoes-de-pagamento"
                            element={<CondicoesDePagamento />}
                          />
                          <Route
                            path="/cadastro/administradora-de-cartoes"
                            element={<AdministradoraDeCartoes />}
                          />
                          <Route
                            path="/cadastro/classificacao-receitas-despesa"
                            element={<ClassificacaoReceitasDespesas />}
                          />
                          <Route
                            path="/cadastro/plano-de-conta-dre"
                            element={<PlanoDeContasDRE />}
                          />
                          <Route
                            path="/cadastro/tabela-despesas"
                            element={<TabelaDespesas />}
                          />
                          <Route
                            path="/cadastro/tabela-receitas"
                            element={<TabelaReceitas />}
                          />
                          <Route
                            path="/cadastro/regioes"
                            element={<Regioes />}
                          />
                          <Route
                            path="/cadastro/cidades"
                            element={<Cidades />}
                          />
                          <Route path="/cadastro/paises" element={<Paises />} />
                          <Route
                            path="/cadastro/cadastro-de-dolares"
                            element={<CadastroDeDolares />}
                          />
                          <Route
                            path="/cadastro/feriados"
                            element={<Feriados />}
                          />
                          {/*----------------------------------------------------*/}
                        </Route>
                      </Route>
                    </Routes>
                  </ProviderContextLogin>
                </ClientesHistoricoCompraProvider>
              </MainProvider>
            </Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

export default Router
