import { ReactElement } from 'react'
import 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

interface Props {
  label?: string
  labels: string[]
  dados: number[] | string[]
  labelColors: string[]
  options?: any
}

const Chart = ({
  label,
  labels,
  labelColors,
  dados,
  options,
}: Props): ReactElement => {
  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.7,
        label,
        data: dados,
        backgroundColor: labelColors,
        borderRadius: 4,
      },
    ],
  }

  return <Bar options={options} data={data} height="100%" />
}
export default Chart

Chart.defaultProps = {
  label: '',
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
    layout: {
      padding: {
        right: 30,
        top: 30,
      },
    },
    maintainAspectRatio: false,
  },
}
