import { MaxCard } from 'maxscalla-lib'

export const NaoExistemDados = () => {
  return (
    <MaxCard.Container className="h-100">
      <MaxCard.Body className="d-flex align-items-center justify-content-center">
        <img src="/images/sem-dados.png" alt="" />
      </MaxCard.Body>
    </MaxCard.Container>
  )
}
