export const getSrcLogoAdministradora = (adm: string) => {
  switch (adm) {
    case 'CIELO':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-cielo.png'
    case 'REDE':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-rede.png'
    case 'TICKET':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-ticket.png'
    case 'SODEXO':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-sodexo.png'
    case 'VR':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-vr3.png'
    case 'ALELO':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-alelo.png'
    case 'GETNET':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-getnet.png'
    case 'BIN':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-bin.png'
    case 'BEN':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-ben-60x37.png'
    case 'STONE':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-stone.png'
    case 'PAGSEGURO':
      return 'http://images.maxscalla.com.br/logos-administradoras/logo-pagseguro.png'
    default:
      return 'http://images.maxscalla.com.br/logos-administradoras/indisponivel02.png'
  }
}
