/* eslint-disable react-hooks/exhaustive-deps */

import { IPedido, unidadeDeNegocios } from '@types'

import {
  maskMoney,
  traducaoFormaPagamentoPelaSigla,
  traducaoFormaPagamentoPeloCodigo,
  maskTelefone,
} from 'utils'

import Modal from 'react-modal'
import { HeaderModal } from 'components'
import { Button } from 'maxscalla-lib'
import { LegacyRef, useRef } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print'

import { ImpressaoA4 } from './components/a4'
import { Impressao56 } from './components/56columns'

interface ModalDetalhesPedidoProps {
  pedido: IPedido
  isOpen: boolean
  unidadeDeNegocios: unidadeDeNegocios[]
  onRequestClose: () => void
}

export const ModalDetalhesPedido = ({
  pedido,
  isOpen,
  unidadeDeNegocios,
  onRequestClose,
}: ModalDetalhesPedidoProps) => {
  const ref56 = useRef() as LegacyRef<HTMLTableSectionElement> | undefined as {
    current: any
  }
  const refA4 = useRef<any>(null)

  return (
    <>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
          style={{
            content: { padding: 0, marginTop: 60 },
          }}
        >
          <HeaderModal
            title="Detalhes do pedido"
            onRequestClose={onRequestClose}
          />
          <div style={{ display: 'flex' }}>
            <ImpressaoA4 pedido={pedido} refA4={refA4} />
          </div>

          <div style={{ display: 'none' }}>
            <Impressao56
              pedido={pedido}
              unidadeDeNegocios={unidadeDeNegocios}
              ref56={ref56}
            />
          </div>
          <div
            className="pd-b-15 d-flex w-100 align-items-center pd-15 pd-r-25 pd-l-25"
            style={{
              borderTop: '1px solid #dbdfea',
              justifyContent: 'space-between',
            }}
          >
            <div />

            <div className="d-flex gap-10-px">
              <Button
                bgColor="#dbdfea"
                textColor="#1c2b46"
                onClick={onRequestClose}
              >
                Cancelar
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button bgColor="#dbdfea" textColor="#1c2b46">
                    Imprimir em A4
                  </Button>
                )}
                content={() => refA4?.current}
              />
              <ReactToPrint
                trigger={() => (
                  <Button bgColor="#dbdfea" textColor="#1c2b46">
                    Imprimir em 56 colunas
                  </Button>
                )}
                content={() => ref56.current}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
