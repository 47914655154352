import { Select } from 'maxscalla-lib'

const statusColumns = [
  { translated: 'Fechamento de contas', untranslated: 'Fech.contas' },
  { translated: 'Reserva', untranslated: 'Reser' },
  { translated: 'Prazo', untranslated: 'Pr' },
  { translated: 'Venda', untranslated: 'Venda' },
  { translated: 'Devolução', untranslated: 'Dev' },
]

interface ISelectTipo {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectStatus = ({ value, setValue }: ISelectTipo) => {
  return (
    <Select
      name="select-status"
      value={String(value) || ''}
      focusColor="blue"
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Status" />
      <option hidden>Status</option>
      {statusColumns.map((option) => (
        <option key={option.untranslated} value={option.untranslated}>
          {option.translated}
        </option>
      ))}
    </Select>
  )
}
