/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { IChildren, ILogin } from '../@types'
import { IEmpresaCs, ILoja } from '../@types/api-cs'
import api from '../api'
import { setTokenAfterLogin } from '../api/xhr'
import { notifyErrorCatch } from '../utils'

export interface StateLoginContext {
  tokenAuth: string
  empresa: IEmpresaCs | null
  lojas: ILoja[]
  login: (params: ILogin) => Promise<void>
  sair: () => void
}

export const ContextLogin = createContext({} as StateLoginContext)

const isGestor = window.location.href.includes('gestor')

const ProviderContextLogin = ({ children }: IChildren): ReactElement => {
  const navigate = useNavigate()
  const [empresa, setEmpresa] = useState<IEmpresaCs | null>(() => {
    const empresaLocalStorage = localStorage.getItem('@CSGestor:empresa')

    if (!empresaLocalStorage) return null

    return JSON.parse(empresaLocalStorage)
  })
  const [tokenAuth, setTokenAuth] = useState<string>(() => {
    const tokenLocalStorage = localStorage.getItem('@CSGestor:token')

    if (!tokenLocalStorage) return ''

    return tokenLocalStorage
  })
  const [lojas, setLojas] = useState<ILoja[]>(() => {
    const lojasLoclStorage = localStorage.getItem('@CSGestor:lojas')

    if (!lojasLoclStorage || lojasLoclStorage === 'undefined') return []

    return JSON.parse(lojasLoclStorage)
  })

  const buscarLojas = useCallback(async () => {
    try {
      if (!tokenAuth.length) return

      const { data } = await api.getLojasLogin(tokenAuth)

      let master: ILoja = {} as ILoja
      let lojasWithOutMaster: ILoja[] = []

      data.forEach((loja) => {
        if (Number(loja.codigo) === 999) {
          master = loja
          return
        }

        lojasWithOutMaster.push(loja)
      })

      const lojasWithFirstMaster = [master, ...lojasWithOutMaster]

      localStorage.setItem(
        '@CSGestor:lojas',
        JSON.stringify(lojasWithFirstMaster),
      )

      setLojas(lojasWithFirstMaster)
    } catch (error) {
      notifyErrorCatch(error, 'Erro ao buscar as suas unidades de negócio!')
    }
  }, [empresa, tokenAuth])

  const login = async (params: ILogin) => {
    try {
      const { data } = await api.login(params)

      setTokenAuth(data.tokenAuth)
      setEmpresa(data.empresa)

      localStorage.setItem('@CSGestor:token', data.tokenAuth)
      localStorage.setItem('@CSGestor:empresa', JSON.stringify(data.empresa))

      setTokenAfterLogin(data.tokenAuth)

      if (isGestor) {
        navigate('/demonstrativos/comercial-dashboards')
      } else {
        navigate('/caixas/detalhamento')
      }
      window.location.reload()
    } catch (error) {
      notifyErrorCatch(error, 'Erro ao efetuar o login!')
    }
  }

  const sair = () => {
    localStorage.removeItem('@CSGestor:token')
    localStorage.removeItem('@CSGestor:empresa')
    localStorage.removeItem('@CSGestor:lojas')

    setTokenAuth('')
    setEmpresa(null)
    setLojas([])

    navigate('/')
  }

  useEffect(() => {
    buscarLojas()
  }, [buscarLojas])

  return (
    <ContextLogin.Provider
      value={{
        tokenAuth,
        empresa,
        lojas,
        login,
        sair,
      }}
    >
      {children}
    </ContextLogin.Provider>
  )
}

export default ProviderContextLogin
