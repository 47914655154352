import { ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuthentication from '../hooks/useAuthentication'

const PrivateRoute = ({ children }: any): ReactElement => {
  const { tokenAuth } = useAuthentication()

  if (!tokenAuth.length) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default PrivateRoute
