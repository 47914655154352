import { Select } from 'maxscalla-lib'

const colunasFormaPagamento = [
  { translated: 'Baixa Automática', untranslated: 'C' },
  { translated: 'Cartão', untranslated: '34' },
  { translated: 'Cartão de Crédito', untranslated: '3' },
  { translated: 'Cartão de Débito', untranslated: '4' },
  { translated: 'Cheque', untranslated: '2' },
  { translated: 'Crédto', untranslated: '9' },
  { translated: 'Cupom de Desconto', untranslated: 'G' },
  { translated: 'Depósito', untranslated: 'K' },
  { translated: 'Depósito Bancário', untranslated: 'F' },
  { translated: 'Desconto', untranslated: 'J' },
  { translated: 'Dinheiro', untranslated: '1' },
  { translated: 'Débito', untranslated: 'B' },
  { translated: 'Duplicata', untranslated: '5' },
  { translated: 'Estorno', untranslated: 'D' },
  { translated: 'Financiamento', untranslated: '6' },
  { translated: 'Gorjeta', untranslated: 'H' },
  { translated: 'Ifood', untranslated: 'L' },
  { translated: 'Link de Pagamento', untranslated: 'M' },
  { translated: 'Lote', untranslated: 'E' },
  { translated: 'Pix', untranslated: 'X' },
  { translated: 'Crédto', untranslated: '9' },
  { translated: 'Troco', untranslated: '8' },
  { translated: 'Troco Disp.', untranslated: 'A' },
  { translated: 'Transferência Bancária', untranslated: 'I' },
  { translated: 'Vale', untranslated: '7' },
  { translated: 'Voucher', untranslated: 'V' },
]

interface ISelectFormatPagamento {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectFormaPagamento = ({
  value,
  setValue,
}: ISelectFormatPagamento) => {
  return (
    <Select
      name="select-descricao-operacao"
      value={String(value) || ''}
      focusColor={'blue'}
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Selecione uma forma de pagamento" />
      <option hidden>Selecione uma forma de pagamento</option>
      {colunasFormaPagamento.map((option) => (
        <option key={option.untranslated} value={option.untranslated}>
          {option.translated}
        </option>
      ))}
    </Select>
  )
}
