import { CloseModalX } from './styles'

interface HeaderModalProps {
  title: string
  onRequestClose: () => void
}

export const HeaderModal = ({ title, onRequestClose }: HeaderModalProps) => {
  return (
    <div
      className="pd-b-15 d-flex w-100 align-items-center pd-15 pd-r-25 pd-l-25"
      style={{
        borderBottom: '1px solid #dbdfea',
        justifyContent: 'space-between',
      }}
    >
      <h5 className="fs-4 cs-color-dark pd-r-5" style={{ margin: 0 }}>
        {title}
      </h5>
      <CloseModalX onClick={onRequestClose} className="fa-regular fa-xmark" />
    </div>
  )
}
