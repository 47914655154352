import { ReactNode, useState } from 'react'
import { createContext } from 'use-context-selector'

interface IFechamentosCaixaContextProps {
  children: ReactNode
}
export type Status =
  | 'todosCaixas'
  | 'caixasNaoConferidos'
  | 'caixasConferidos'
  | 'caixasAbertos'
  | 'caixasFechados'

interface FechamentosCaixaContextProps {
  status: Status
  changeStatus: (value: Status) => void
}

export const FechamentosCaixaContext = createContext(
  {} as FechamentosCaixaContextProps,
)

export const FechamentosCaixaProvider = ({
  children,
}: IFechamentosCaixaContextProps) => {
  const [status, setStatus] = useState<Status>('todosCaixas')
  const changeStatus = (value: Status) => {
    setStatus(value)
  }
  const values = { status, setStatus, changeStatus }

  return (
    <FechamentosCaixaContext.Provider value={values}>
      {children}
    </FechamentosCaixaContext.Provider>
  )
}
