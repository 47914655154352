/* eslint-disable react-hooks/exhaustive-deps */

import { IPedido, unidadeDeNegocios } from '@types'
import moment from 'moment'
import React, { MutableRefObject, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { maskMoney, maskPercentage } from 'utils'
import { traducaoStatusOperacao } from 'utils/traducoes/traducao-status-operacao'

interface ImpressaoA4Props {
  pedido: IPedido
  unidadeDeNegocios: unidadeDeNegocios[]
  ref56: any
}
export const Impressao56 = ({
  pedido,
  ref56,
  unidadeDeNegocios,
}: ImpressaoA4Props) => {
  const statusOperacao = traducaoStatusOperacao(pedido.status)
  const format = (value: string | null): string => {
    if (!value) return '...'
    return moment(value).format('DD/MM/YYYY')
  }
  const hasDiscountItems = pedido.itens.some((item) => item.desconto)

  return (
    <div
      ref={ref56}
      style={{
        width: '56ch',
        display: 'flex',
        justifyContent: 'center',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <div
        style={{
          width: '98%',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '13px',
          fontFamily: 'sans-serif',
        }}
      >
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            marginBottom: '6px',
          }}
        >
          <p style={{ height: '4px' }}>
            {statusOperacao} Nº {pedido.codigo}
          </p>
          <p style={{ height: '4px' }}>
            {format(pedido.data)} - {pedido.hora}
          </p>
        </div>
        <div style={{ borderTop: '1px dashed black' }} />
        <div style={{ marginBottom: '6px', marginTop: '6px' }}>
          <p style={{ height: '4px' }}>{unidadeDeNegocios[0].nomeFantasia}</p>
          <p style={{ height: '4px' }}>
            {unidadeDeNegocios[0].endereco} -
            {unidadeDeNegocios[0].numeroEndereco}
          </p>
          <p style={{ height: '4px' }}>
            {unidadeDeNegocios[0].bairro} - {unidadeDeNegocios[0].cidade} -{' '}
            {unidadeDeNegocios[0].estado} - CEP {unidadeDeNegocios[0].cep}
          </p>
        </div>
        <div style={{ borderTop: '1px dashed black', marginBottom: '6px' }} />
        <div style={{ marginBottom: '6px' }}>
          <p style={{ height: '4px' }}>
            CLIENTE: {pedido.codigoCiente} - {pedido.nomeCliente?.trim()}
          </p>
          <div
            style={{
              display: pedido.codigoCiente !== 1 ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <p style={{ height: '4px' }}>
              {pedido.endereco?.trim()}, {pedido.numeroEndereco?.trim()}
            </p>
            <p style={{ height: '4px' }}>
              {pedido.bairro?.trim()} - {pedido.cidade?.trim()}
            </p>
            <p style={{ height: '4px' }}>
              {pedido.estado.trim()} - {pedido.cep.trim()}
            </p>
          </div>
        </div>
        <div style={{ borderTop: '1px dashed black', marginBottom: '6px' }} />

        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <p style={{ height: '4px' }}>QTDE</p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <p style={{ height: '4px' }}>UNID</p>
          </div>
          {hasDiscountItems ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <p style={{ height: '4px' }}>%DESC</p>
            </div>
          ) : null}

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <p style={{ height: '4px' }}>PRECO</p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p style={{ height: '4px' }}>TOTAL</p>
          </div>
        </div>
        <div
          style={{
            borderTop: '1px dashed black',
            marginBottom: '6px',
            marginTop: '6px',
          }}
        />
        {pedido.itens.map((item) => (
          <>
            <div key={item.codigo}>
              <p
                style={{
                  height: '4px',
                }}
              >
                {item.descricao?.trim()}
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <p style={{ height: '4px' }}>{item.quantidade}</p>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <p style={{ height: '4px' }}>{item.embalagem?.trim()}</p>
                </div>
                {hasDiscountItems ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <p style={{ height: '4px' }}>
                      {item.desconto ? maskPercentage(item.desconto) : '0,00%'}
                    </p>
                  </div>
                ) : null}

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <p style={{ height: '4px' }}>{maskMoney(item.preco)}</p>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <p style={{ height: '4px' }}>
                    {maskMoney(item.preco * item.quantidade)}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
        <div
          style={{
            borderTop: '1px dashed black',
            marginBottom: '8px',
            marginTop: '6px',
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <p style={{ height: '4px' }}>
            (=) TOTAL DAS MERCADORIAS:{' '}
            {maskMoney(
              Number(pedido.valor) +
                Number(pedido.desconto) -
                Number(pedido.outrasDespesas),
            )}
          </p>
        </div>

        {Number(pedido.desconto) !== 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <p style={{ height: '4px' }}>
                (-) DESCONTO: {''} {maskMoney(pedido.desconto)}
              </p>
            </div>
          </>
        ) : null}
        {Number(pedido.outrasDespesas) !== 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <p style={{ height: '4px' }}>
                (+) DESPESAS: {''}
                {maskMoney(pedido.outrasDespesas)}
              </p>
            </div>
          </>
        ) : null}

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <p style={{ fontWeight: 'bold', height: '4px' }}>
            (=) TOTAL DO PEDIDO: {''}
            {maskMoney(pedido.valor)}
          </p>
        </div>
        <div
          style={{
            borderTop: '1px dashed black',
            marginBottom: '6px',
            marginTop: '6px',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '4px',
          }}
        >
          <p style={{ height: '4px' }}>
            {pedido.descricaoCondicaoPagamento} ({pedido.condicaoPagamento})
          </p>
          <p style={{ height: '4px' }}>Observações: </p>
        </div>

        {pedido.observacao && pedido.observacao.length && (
          <>
            {[
              pedido.observacao.trim(),
              pedido.observacaoDois?.trim(),
              pedido.observacaoTres?.trim(),
              pedido.observacaoQuatro?.trim(),
              pedido.observacaoCinco?.trim(),
            ].filter((obs) => obs !== '').length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '4px',
                }}
              >
                {pedido.observacao && pedido.observacao !== '' && (
                  <p style={{ height: '4px' }}>{pedido.observacao}</p>
                )}
                {pedido.observacaoDois && pedido.observacaoDois !== '' && (
                  <p style={{ height: '4px' }}>{pedido.observacaoDois}</p>
                )}
                {pedido.observacaoTres && pedido.observacaoTres !== '' && (
                  <p style={{ height: '4px' }}>{pedido.observacaoTres}</p>
                )}
                {pedido.observacaoQuatro && pedido.observacaoQuatro !== '' && (
                  <p style={{ height: '4px' }}>{pedido.observacaoQuatro}</p>
                )}
                {pedido.observacaoCinco && pedido.observacaoCinco !== '' && (
                  <p style={{ height: '4px' }}>{pedido.observacaoCinco}</p>
                )}
              </div>
            )}
          </>
        )}

        {pedido.status === 'Prazo' ||
        pedido.status === 'PrazP' ||
        pedido.status === 'DevoP' ||
        pedido.status === 'DevPz' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginBottom: '6px',
              width: '100%',
            }}
          >
            <div
              style={{
                borderTop: '1px dashed black  ',
                marginBottom: '6px',
                marginTop: '48px',
                width: '80%',
              }}
            />
            <p style={{ height: '4px' }}>ASSINATURA</p>
          </div>
        ) : null}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>*** DOCUMENTO SEM VALOR FISCAL ***</p>
        </div>
      </div>
    </div>
  )
}
