import { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useAuthentication from '../../hooks/useAuthentication'

import { Avatar, Header as MaxHeader } from 'maxscalla-lib'
import { IconHeader, TextHeader } from './styles'
import { clickOutside } from '../../utils/clickOutside'
import { CardUser } from './components/CardUser'

const Header = (): ReactElement => {
  const [showCardUser, setShowCardUser] = useState<boolean>(false)

  const { empresa, sair } = useAuthentication()
  const { pathname } = useLocation()

  const logo = window.location.href.includes('gestor')
    ? '/images/logo_gestor.png'
    : '/images/logo-controlshop.png'

  const onChangeCardUser = (x: boolean) => {
    setShowCardUser(x)
  }

  const handleActive = (page: string): string => {
    const url = String(pathname)

    if (url.includes(page)) return 'bg-active cs-color-white active'

    return ''
  }

  function handleFullScreen() {
    if (document.fullscreen) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }

  return (
    <MaxHeader pathImage={logo}>
      <div className="options">
        <div className="option" onClick={handleFullScreen}>
          <i className="fa-duotone fa-expand fa-2xl" />
        </div>
        <TextHeader className="option">
          <span className="fs-6">{empresa?.conexao.apelido}</span>
        </TextHeader>

        <IconHeader
          className="option min-w-70px"
          noHover
          onClick={() => {
            clickOutside('#card-user', showCardUser, onChangeCardUser)
            setTimeout(() => setShowCardUser(!showCardUser), 100)
          }}
        >
          <Avatar
            bgColor="blue"
            size="md"
            letter={String(empresa?.usuario.usuario).substring(0, 1)}
          />
        </IconHeader>

        <CardUser show={showCardUser} singOut={sair} empresa={empresa} />
      </div>
    </MaxHeader>
  )
}

export default Header
