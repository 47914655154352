import styled from 'styled-components'

export const Container = styled.div`
  height: fit-content;
  width: fit-content;

  z-index: 1;
  position: absolute;

  margin: 0 auto;
  right: 0px;
  left: -15px;
  bottom: 0px;
  top: 45px;
`

export const Calender = styled.div`
  .years {
    display: grid;
    grid-auto-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    div {
      padding: 5px;
      padding: 5px 7px;
      cursor: pointer;
    }

    .active {
      border-radius: 10px;
      padding: 5px;
      color: ${({ theme }) => theme.blue};
      font-weight: bold;
      padding: 5px 7px;
    }
  }
`

export const ContainerSelectDate = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 575px) {
    justify-content: center;
  }

  .select-date {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;

    gap: 30px;
    padding-inline: 16px;

    border: 1px solid #e4e6ef;
    border-radius: 4px;

    height: 36px;
    width: fit-content;
  }
`

// className="flex-1 align-items-center d-flex"
// style={{ justifyContent: 'end' }}
