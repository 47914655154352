import { IFilters } from '@types'

import { MainContext } from 'contexts/mainContext'
import useAuthentication from 'hooks/useAuthentication'
import { useContextSelector } from 'use-context-selector'

import DatePickerRange from 'components/DatePickerRange'
import SelectLoja from 'components/SelectLoja'
import * as S from './styles'
import { ReactNode } from 'react'

interface SubHeaderProps {
  title: string
  dataInicial?: string
  dataFinal?: string
  changeFilters?: (value: IFilters) => void
  isDateDisabled?: boolean
  children?: ReactNode
}

export const NewSubHeader = ({
  title,
  dataInicial,
  dataFinal,
  changeFilters,
  isDateDisabled = false,
  children,
}: SubHeaderProps) => {
  const { lojas } = useAuthentication()
  const { numeroLoja, mainDataInicial, mainDataFinal, mainChangeFilters } =
    useContextSelector(MainContext, (context) => {
      return {
        numeroLoja: context.filters.numeroLoja,
        mainDataInicial: context.filters.dataInicial,
        mainDataFinal: context.filters.dataFinal,
        mainChangeFilters: context.changeFilters,
      }
    })

  return (
    <S.SubHeader>
      <div className="card card-custom">
        <div
          className="card-header min-h-55px"
          style={{ padding: '0 1.25rem' }}
        >
          <h3 className="card-title align-items-start flex-column mg-0">
            <span className="card-label fw-bold text-dark">{title}</span>
          </h3>
          <div className="card-toolbar mg-0">
            <div className="d-flex flex-row gap-20-px">
              {children}
              {isDateDisabled ? null : (
                <DatePickerRange
                  id="range-date-table-home"
                  dataInicial={
                    changeFilters
                      ? dataInicial || mainDataInicial
                      : mainDataInicial
                  }
                  dataFinal={
                    changeFilters ? dataFinal || mainDataFinal : mainDataFinal
                  }
                  month
                  onChange={(dataInicial: string, dataFinal: string) => {
                    changeFilters
                      ? changeFilters({ dataInicial, dataFinal })
                      : mainChangeFilters({ dataInicial, dataFinal })
                  }}
                />
              )}

              {lojas.length > 1 ? (
                <SelectLoja
                  stores={lojas}
                  numberSelectedStore={Number(numeroLoja)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </S.SubHeader>
  )
}
