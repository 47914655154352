import useHideTotals from 'hooks/reduce/useHideTotals'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { setIsHideTotals } from 'storeRedux/slices/hideTotals'
import Header from './Header'
import { Loading } from './Loading'
import { SideBar } from './SideBar'

const Container = () => {
  const dispatch = useDispatch()
  const { isHideTotals } = useHideTotals()

  useEffect(() => {
    const scrollToTop = document.querySelector('.scrolltop')

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 100) {
        scrollToTop?.classList.add('scrolltop-active')
      } else {
        scrollToTop?.classList.remove('scrolltop-active')
      }
    })
  }, [])

  return (
    <div className="max-main">
      <Loading />
      <Header />
      <SideBar />
      <div
        className="d-flex flex-column w-100"
        style={{
          background: '#f2f3f8',
          overflowX: 'clip',
        }}
      >
        <Outlet />
        <div
          id="kt_app_footer"
          className="app-footer"
          style={{ borderTop: '1px solid var(--kt-card-border-color)' }}
        >
          <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
            <div className="text-dark order-2 order-md-1">
              <a
                href="https://maxscalla.com.br"
                target="_blank"
                className="text-gray-800 text-hover-primary"
                rel="noreferrer"
              >
                Desenvolvido por Max Scalla Informática | 2022{' '}
              </a>
              <button
                style={{ color: 'white' }}
                onClick={() => dispatch(setIsHideTotals(!isHideTotals))}
              >
                Click
              </button>
            </div>
          </div>
        </div>
        <div
          className="scrolltop hover-opacity-1"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <span className="svg-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="13"
                y="6"
                width="13"
                height="2"
                rx="1"
                transform="rotate(90 13 6)"
                fill="currentColor"
              ></rect>
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Container
