const MESES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

const SQL_VALPED =
  "CASE WHEN CFITPNPED LIKE '%NF%' AND CFIVNFPED <> 0 THEN CFIVNFPED ELSE CFIVALPED END"

const SQL_VALFCO =
  "CASE WHEN ( NOT CFITPNFCO LIKE '%NF%' ) OR ( CFIVNFFCO = 0 ) THEN CFIVALFCO ELSE CFIVNFFCO END"

export { MESES, SQL_VALFCO, SQL_VALPED }
