import styled from 'styled-components'

export const Container = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #ebedf2;

  position: sticky;
  z-index: 9;
  top: 70px;
`
