import { getElementPorId } from '.'

const show = () => {
  const elemento = getElementPorId('loading')

  elemento.className = 'd-flex-important h-100'
}

const hide = () => {
  const elemento = getElementPorId('loading')

  elemento.className = 'd-flex-none h-100'
}

const loading = {
  show,
  hide,
}

export default loading
