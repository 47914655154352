import { createSlice } from '@reduxjs/toolkit'

export interface HideTotals {
  isHideTotals: boolean
}

const slice = createSlice({
  name: 'hideTotals',
  initialState: {
    isHideTotals: true,
  },
  reducers: {
    setIsHideTotals(state: HideTotals, action) {
      return { ...state, isHideTotals: action.payload }
    },
  },
})

export const { setIsHideTotals } = slice.actions

export default slice.reducer
