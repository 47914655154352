import { colors } from 'maxscalla-lib'
import { ReactElement } from 'react'

interface Props {
  text: string
  colSpan: number
}

export const TableEmpty = ({ text, colSpan }: Props): ReactElement => (
  <tr>
    <td colSpan={colSpan} className="example-alert p-0">
      <div className="alert alert-fill alert-light alert-icon mg-0">
        <em className="icon ni ni-alert-circle" />
        <strong style={{ color: colors.indigo }}>{text}</strong>
      </div>
    </td>
  </tr>
)
