/* eslint-disable react-hooks/exhaustive-deps */

import { IPedido } from '@types'

import {
  maskMoney,
  traducaoFormaPagamentoPelaSigla,
  traducaoFormaPagamentoPeloCodigo,
  maskTelefone,
} from 'utils'

import { Button } from 'maxscalla-lib'
import { LegacyRef, useRef } from 'react'

interface ImpressaoA4Props {
  pedido: IPedido
  refA4: any
}
export const ImpressaoA4 = ({ pedido, refA4 }: ImpressaoA4Props) => {
  const codigoFormaPagamento = traducaoFormaPagamentoPelaSigla(
    pedido.formaPagamento,
  )

  const configuracoes: any = {}

  const formaPagamentoTraduzida =
    traducaoFormaPagamentoPeloCodigo(codigoFormaPagamento)

  return (
    <div>
      <div className="p-7">
        <table
          width="100%"
          cellPadding={10}
          cellSpacing={0}
          style={{ background: '#F1F1F1' }}
        >
          <tbody>
            <tr>
              <td width="100%" align="center">
                <table
                  width={800}
                  cellPadding={0}
                  cellSpacing={0}
                  style={{ background: '#FFF', marginBottom: 20 }}
                >
                  <tbody ref={refA4}>
                    <tr>
                      <td>
                        <table width="100%" cellPadding={10}>
                          <tbody>
                            <tr>
                              <td width="100%">
                                <table width="100%" cellPadding={10}>
                                  <thead style={{ background: '#ebecf0' }}>
                                    <tr>
                                      <th
                                        style={{
                                          background: '#ebecf0',
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'left',
                                        }}
                                      >
                                        DADOS DO CLIENTE
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '5px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        {pedido.nomeCliente?.trim()}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        {pedido?.endereco?.trim() ? (
                                          <>
                                            {pedido?.endereco?.trim()}
                                            ,&nbsp;
                                          </>
                                        ) : null}
                                        {pedido?.numeroEndereco?.trim() ? (
                                          <>
                                            {pedido?.numeroEndereco?.trim()}
                                            &nbsp;
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        {pedido?.bairro?.trim() ? (
                                          <>
                                            {pedido?.bairro?.trim()}
                                            &nbsp;-&nbsp;
                                          </>
                                        ) : null}
                                        {pedido?.cidade?.trim() ? (
                                          <>
                                            {pedido?.cidade?.trim()}
                                            &nbsp;-&nbsp;
                                          </>
                                        ) : null}
                                        {pedido?.estado?.trim() ? (
                                          <>
                                            {pedido?.estado?.trim()}
                                            &nbsp;-&nbsp;
                                          </>
                                        ) : null}
                                        {pedido?.cep?.trim() ? (
                                          <>
                                            &nbsp;CEP&nbsp;
                                            {pedido?.cep?.trim()}
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 5px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                        }}
                                      >
                                        {pedido.telefone ? (
                                          <>
                                            Fone:&nbsp;
                                            {maskTelefone(
                                              pedido.telefone?.trim(),
                                            )}
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td width="100%">
                                <table width="100%" cellPadding={10}>
                                  <thead style={{ background: '#ebecf0' }}>
                                    <tr>
                                      <th
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'left',
                                        }}
                                      >
                                        DETALHES DA OPERAÇÃO
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '5px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        <b>
                                          ORÇAMENTO N. &nbsp;
                                          {pedido.codigo}
                                        </b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        Condição de pagamento: &nbsp;
                                        {pedido.descricaoCondicaoPagamento?.trim()}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        Forma de pagamento: &nbsp;
                                        {formaPagamentoTraduzida}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width={800}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '0px 10px 2px',
                                          border: '1px solid #eee',
                                          borderTop: 'none',
                                          borderBottom: 'none',
                                        }}
                                      >
                                        Vendedor: &nbsp;
                                        {pedido.codigoRepresentante}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table width="100%" cellPadding={10}>
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  width="100%"
                                  cellPadding={10}
                                  cellSpacing={0}
                                >
                                  <thead style={{ background: '#ebecf0' }}>
                                    <tr>
                                      <th
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'left',
                                          border: '1px solid #fff',
                                          borderBottom: 0,
                                        }}
                                      >
                                        DESCRIÇÃO DO PRODUTO
                                      </th>
                                      <th
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'right',
                                          border: '1px solid #fff',
                                          borderBottom: 0,
                                        }}
                                      >
                                        QTDE
                                      </th>
                                      <th
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'right',
                                          border: '1px solid #fff',
                                          borderBottom: 0,
                                        }}
                                      >
                                        PREÇO (unid)
                                      </th>
                                      <th
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'right',
                                          border: '1px solid #fff',
                                          borderBottom: 0,
                                        }}
                                      >
                                        TOTAL
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pedido.itens?.length ? (
                                      <>
                                        {pedido.itens.map((item) => (
                                          <tr key={item.codigo}>
                                            <td
                                              style={{
                                                fontFamily:
                                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                                fontSize: 12,
                                                color: '#555',
                                                padding: 10,
                                                textAlign: 'left',
                                                border: '1px solid #eee',
                                              }}
                                            >
                                              {item.codigo}
                                              &nbsp;-&nbsp;
                                              {item.descricao?.trim()}
                                              &nbsp;-&nbsp;
                                              {item.embalagem?.trim()}
                                              {item.observacao?.trim()
                                                ?.length ? (
                                                <div className="smaller">
                                                  {item.observacao?.trim()}
                                                </div>
                                              ) : null}
                                            </td>
                                            <td
                                              style={{
                                                fontFamily:
                                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                                fontSize: 12,
                                                color: '#555',
                                                padding: 10,
                                                textAlign: 'right',
                                                border: '1px solid #eee',
                                              }}
                                            >
                                              {parseFloat(
                                                String(item.quantidade),
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                fontFamily:
                                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                                fontSize: 12,
                                                color: '#555',
                                                padding: 10,
                                                textAlign: 'right',
                                                border: '1px solid #eee',
                                              }}
                                            >
                                              {Number(item.precoLista) !==
                                              Number(item.preco) ? (
                                                <>
                                                  <div
                                                    style={{
                                                      fontSize: 11,
                                                      color: '#e7505a',
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    De&nbsp;
                                                    {maskMoney(item.precoLista)}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      bottom: 2,
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    por&nbsp;
                                                    {maskMoney(item.preco)}
                                                  </div>
                                                </>
                                              ) : (
                                                <div>
                                                  {maskMoney(item.preco)}
                                                </div>
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                fontFamily:
                                                  'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                                fontSize: 12,
                                                color: '#555',
                                                padding: 10,
                                                textAlign: 'right',
                                                border: '1px solid #eee',
                                              }}
                                            >
                                              {maskMoney(
                                                Number(item.quantidade) *
                                                  Number(item.preco),
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    ) : null}
                                    {Number(pedido.desconto) !== 0 ? (
                                      <tr>
                                        <td
                                          colSpan={2}
                                          style={{
                                            fontFamily:
                                              'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            color: '#555',
                                            textAlign: 'left',
                                          }}
                                        >
                                          DESCONTO
                                        </td>
                                        <td
                                          colSpan={2}
                                          style={{
                                            fontFamily:
                                              'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            color: '#555',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {maskMoney(pedido.desconto)}
                                        </td>
                                      </tr>
                                    ) : null}
                                    <tr>
                                      <td
                                        colSpan={2}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                          color: '#555',
                                          textAlign: 'left',
                                        }}
                                      >
                                        TOTAL GERAL
                                      </td>
                                      <td
                                        colSpan={2}
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 20,
                                          fontWeight: 'bold',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {maskMoney(pedido.valor)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table width="100%" cellPadding={10}>
                          <tbody>
                            <tr>
                              <td>
                                <table width="100%" cellPadding={10}>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          fontWeight: 'bold',
                                          padding: 10,
                                          textAlign: 'left',
                                        }}
                                      >
                                        Observações gerais:
                                      </td>
                                      <td
                                        style={{
                                          width: '80%',
                                          fontFamily:
                                            'Arial, "Helvetica Neue", Helvetica, sans-serif',
                                          fontSize: 12,
                                          color: '#555',
                                          padding: '15px 10px',
                                          textAlign: 'left',
                                          border: '1px solid #eee',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        Validade deste orçamento: &nbsp;
                                        {configuracoes?.validadeOrcamento}
                                        &nbsp;
                                        {configuracoes?.validadeOrcamento &&
                                        configuracoes?.validadeOrcamento === 1
                                          ? 'dia'
                                          : 'dias'}
                                        <br />
                                        {pedido.observacao &&
                                        pedido.observacao?.trim().length ? (
                                          <span>
                                            Mais informa&ccedil;&otilde;es:
                                            <br />
                                            <div
                                              style={{
                                                width: 400,
                                                wordWrap: 'break-word',
                                              }}
                                            >
                                              {pedido.observacao?.trim()}
                                            </div>
                                          </span>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="pd-b-15 d-flex w-100 align-items-center pd-15 pd-r-25 pd-l-25"
        style={{
          borderTop: '1px solid #dbdfea',
          justifyContent: 'space-between',
        }}
      >
        <div />
      </div>
    </div>
  )
}
