import { InputMaskDate } from 'components/Inputs/InputMaskDate'

interface IInputData {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const InputData = ({ value, setValue }: IInputData) => {
  return (
    <InputMaskDate
      name="select-descricao-operacao"
      id="select-descricao-operacao"
      placeholder="__/__/____"
      className="form-control"
      value={String(value) || ''}
      onChange={(e: any) => setValue(e.target.value)}
    />
  )
}
