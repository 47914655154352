import { ReactElement } from 'react'
import { ILoja } from '../../@types'
import { useState } from 'react'
import { clickOutside } from 'utils/clickOutside'
import { useContextSelector } from 'use-context-selector'
import { MainContext } from 'contexts/mainContext'
import { Ranges } from './style'

type SvgColor = 'currentColor' | '#009ef7'

interface Props {
  stores: ILoja[]
  numberSelectedStore: number | undefined
}

const SelectLoja = ({ stores, numberSelectedStore }: Props): ReactElement => {
  const changeNumeroLoja = useContextSelector(MainContext, (context) => {
    return context.changeNumeroLoja
  })

  const [showStoreList, setShowStoreList] = useState<boolean>(false)
  const [svgColor, setSvgColor] = useState<SvgColor>('currentColor')
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const changeShowStoreList = (x: boolean) => {
    setShowStoreList(x)
  }

  const handleSelect = (loja: ILoja) => {
    const lojaPeloCodigo = stores.find((l) => l.codigo === loja.codigo)

    if (lojaPeloCodigo) {
      changeNumeroLoja(lojaPeloCodigo.codigo)
    }
  }

  const fetchNameLoja = (loja: ILoja) => {
    if (loja.codigo === 999) {
      return 'Master'
    }

    const formatedName =
      loja.fantasia[0].toUpperCase() +
      loja.fantasia.substring(1).toLocaleLowerCase()

    return formatedName
  }

  const fetchStoreNameById = () => {
    const loja = stores.find((x) => x.codigo === numberSelectedStore)

    if (loja?.codigo === 999) return 'Master'

    return loja?.fantasia
  }

  const changeLoja = (operacao: 'sub' | 'add') => {
    if (operacao === 'sub') {
      const prevStore = stores[selectedIndex - 1]

      if (selectedIndex === 0) {
        const lastStore = stores[stores.length - 1]

        setSelectedIndex(stores.length - 1)
        changeNumeroLoja(lastStore.codigo)

        return
      }

      if (prevStore?.codigo) {
        setSelectedIndex(selectedIndex - 1)
        changeNumeroLoja(prevStore.codigo)
      }

      return
    }

    const nextStore = stores[selectedIndex + 1]

    if (selectedIndex === stores.length - 1) {
      const firstStore = stores[0]

      setSelectedIndex(0)
      changeNumeroLoja(firstStore.codigo)

      return
    }

    if (nextStore?.codigo) {
      setSelectedIndex(selectedIndex + 1)
      changeNumeroLoja(nextStore.codigo)
    }
  }

  return (
    <div
      className="btn btn-sm btn-light d-flex align-items-center px-4 min-w-150px gap-10-px"
      style={{
        padding: 'calc(0.55rem - 2px) calc(1.25rem + 1px)',
        position: 'relative',
      }}
    >
      <span
        className="svg-icon svg-icon-1 ms-0 mg-0"
        onClick={() => changeLoja('sub')}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <div className="w-100 d-flex align-items-center">
        <span
          className="svg-icon svg-icon-muted svg-icon-1"
          style={{ marginRight: 10 }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={() => setSvgColor('#009ef7')}
            onMouseLeave={() => setSvgColor('currentColor')}
          >
            <path
              opacity="0.3"
              d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z"
              fill={svgColor}
            />
            <path
              opacity="0.3"
              d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z"
              fill={svgColor}
            />
            <path
              opacity="0.3"
              d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z"
              fill={svgColor}
            />
            <path
              opacity="0.3"
              d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z"
              fill={svgColor}
            />
            <path opacity="0.3" d="M14 4H10V10H14V4Z" fill={svgColor} />
            <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill={svgColor} />
            <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill={svgColor} />
            <path
              d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
              fill={svgColor}
            />
          </svg>
        </span>
        <div
          style={{ width: '100%', color: '#696c78' }}
          onClick={() =>
            clickOutside('#daterangepicker', showStoreList, changeShowStoreList)
          }
        >
          {fetchStoreNameById()}
          <div
            id="daterangepicker"
            className="daterangepicker ltr show-ranges opensleft"
            style={{
              display: showStoreList ? 'block' : 'none',
              inset: 0,
              marginTop: '30px',
            }}
          >
            <Ranges>
              <ul className="p-0 w-100">
                {stores.map((lojaOption, i) => (
                  <li
                    key={lojaOption.codigo}
                    id={String(lojaOption.codigo)}
                    className={
                      lojaOption.codigo === numberSelectedStore ? 'active' : ''
                    }
                    onClick={() => {
                      handleSelect(lojaOption)
                      setSelectedIndex(i)
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    {fetchNameLoja(lojaOption)}
                  </li>
                ))}
              </ul>
            </Ranges>
          </div>
        </div>
      </div>
      <span
        className="svg-icon svg-icon-1 ms-0 mg-0"
        onClick={() => changeLoja('add')}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>
  )
}

export default SelectLoja
