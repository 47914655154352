import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import { useField } from '@unform/core'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
}

const Input: React.FC<InputProps> = ({ name, ...props }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, error, registerField } = useField(name || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        {...props}
        className={`${error ? 'border-danger' : ''} ${props.className}`}
      />
      <span style={{ fontSize: 12 }} className="text-danger">
        {error}
      </span>
    </>
  )
}

Input.defaultProps = {
  name: undefined,
}

export default Input
