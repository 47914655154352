import { ValidationError } from 'yup'

interface Erros {
  [key: string]: string
}

const getValidationError = (err: ValidationError): Erros => {
  const validacaoDeErros: Erros = {}

  if (!err || !err.inner) return {} as Erros

  err.inner.forEach((error) => {
    if (error.path) {
      validacaoDeErros[error.path] = error.message
    }
  })

  return validacaoDeErros
}

export default getValidationError
