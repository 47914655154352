import { useCallback, useEffect, useState } from 'react'

import { notifyErrorCatch } from 'utils'
import api from 'api'

import { CartoesContext } from 'contexts/cartoesContext'
import { useContextSelector } from 'use-context-selector'

import { Select } from 'maxscalla-lib'
import { OptionsAdministradora } from '@types'

interface ISelectOperacao {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectAdministradora = ({ value, setValue }: ISelectOperacao) => {
  const [optionsAdministradora, setOptionsAdministradora] = useState<
    OptionsAdministradora[]
  >([])
  const cartoes = useContextSelector(CartoesContext, (context) => context)

  const getOptions = useCallback(async () => {
    try {
      const params = {
        dataInicial: cartoes.filters.dataInicial,
        dataFinal: cartoes.filters.dataFinal,
      }

      const { data } = await api.getOptionsAdministradoraCartoes(params)

      setOptionsAdministradora(data)
    } catch (error) {
      notifyErrorCatch(error)
    }
  }, [cartoes.filters])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  return (
    <Select
      name="select-FCOorPED"
      value={String(value) || ''}
      focusColor={'blue'}
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Administradora" />
      <option hidden>Administradora</option>
      {optionsAdministradora.map((option) => (
        <option key={option.administradora} value={option.administradora}>
          {option.administradora}
        </option>
      ))}
    </Select>
  )
}
