import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --header: #19212f;

    --dark: #1c2b46;

    --lightGray:#dbdfea;
    --gray: #a6a6a6;
    --darkGray: #292929;
    --blueGray: #526484;

    --indigo: #1c2256;
    --darkBlue: #2c3782;
    --blue: #559bfb;
    --lightBlue: #8cbcfd;
    --lightGreen: #6de6c2;
    --green: #20c997;
    --darkGreen: #116e53;
    --yellow: #ffc053;
    --orange: #ffa353;
    --red: #e85347;

    --background: #F0F2F5;
    --shape: #FFFFFF;

    --light: 300;
    --regular: 400;
    --medium: 500;
    --semiBold: 600;
    --bold: 700;
    --extraBold: 800;
    --black: 900;
  }

  // Seta type number retirada
  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;  
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* html {
    font-size: 16px !important;
  } */

  /* ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: orange;
  }

  ::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
  } */

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 20px;
  }

  canvas {
    max-width: 100%;
    max-height: 100%;
  }

  body, input, textarea, button, select {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #344357;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  tbody {
    position: relative;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    overflow-y: scroll;
    padding: 2rem;

    display: grid;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    margin-inline: 20px;
    padding: 24px;
    
    background: #fff;

    position: relative;
    border-radius: 0.5rem;
    outline: none;
  }

  .react-modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    border: 0;
    background: transparent;

    i {
      font-size: 24px;
      color: var(--gray);
    }

    transition: filter(0.2s);

    &:hover {
      filter: brightness(0.8);
    }
  }
`
