import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import Modal from 'react-modal'
import Router from './routes/Router'
import { GlobalStyle } from 'theme/GlobalStyles'

import { MovimentoVendasProvider } from 'contexts/movimentoVendasContext'
import { DetalhesPedidoProvider } from 'contexts/detalhesPedidoContext'
import { DetalhesPedidoNaoRecebidoProvider } from 'contexts/detalhesPedidoNaoRecebidosContext'
import { FiltersProvider } from 'contexts/filtersContext'
import { CartoesProvider } from 'contexts/cartoesContext'
import { FechamentosCaixaProvider } from 'contexts/fechamentosCaixaContext'

Modal.setAppElement('#root')

ReactDOM.render(
  <StrictMode>
    <FiltersProvider>
      <CartoesProvider>
        <DetalhesPedidoProvider>
          <DetalhesPedidoNaoRecebidoProvider>
            <MovimentoVendasProvider>
              <FechamentosCaixaProvider>
                <Router />
                <GlobalStyle />
              </FechamentosCaixaProvider>
            </MovimentoVendasProvider>
          </DetalhesPedidoNaoRecebidoProvider>
        </DetalhesPedidoProvider>
      </CartoesProvider>
    </FiltersProvider>
  </StrictMode>,
  document.getElementById('root'),
)
