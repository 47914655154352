import { Select } from 'maxscalla-lib'

const statusColumns = [
  { translated: 'Crédito', untranslated: 'C' },
  { translated: 'Débito', untranslated: 'D' },
  { translated: 'Voucher', untranslated: 'V' },
]

interface ISelectModalidade {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectModalidade = ({ value, setValue }: ISelectModalidade) => {
  return (
    <Select
      name="select-modalidade"
      value={String(value) || ''}
      focusColor="blue"
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Modalidade" />
      <option hidden>Modalidade</option>
      {statusColumns.map((option) => (
        <option key={option.untranslated} value={option.untranslated}>
          {option.translated}
        </option>
      ))}
    </Select>
  )
}
