import styled from 'styled-components'

export const CardTotalizador = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 2rem 2.25rem;

  .head {
    div:first-child {
      display: flex;
      flex-direction: column;
    }

    .operacoes {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: flex-start;

      height: fit-content;
      gap: 10px;

      font-size: 18px;
      font-weight: 600;
    }

    .value {
      font-size: 14px;
      font-weight: 400;
      color: #696c78 !important;
    }

    .description {
      font-size: 16px;
      font-weight: 500;
    }
  }

  footer {
    color: #696c78 !important;
  }
`

export const ContainerCardTotalizador = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .box {
    border-right: 1px solid #ebedf2;
  }

  @media (max-width: 1320px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;

    .box {
      border-right: none;
      border-bottom: 1px solid #ebedf2;
    }
  }
`
