import { IChildren, IPedido, unidadeDeNegocios } from '@types'
import api from 'api'
import { ModalDetalhesPedido } from 'pages/Comercial/OperacoesComerciais/components/ModalDetalhesPedido'
import { useCallback, useEffect, useState } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import exibirErrorCatch from 'utils/toast-catch-error'
import { MainContext } from './mainContext'
import { notifyErrorCatch } from 'utils'

interface DetalhesPedidoContextProps {
  isOpenModal: boolean
  closeModal: () => void
  openModal: () => void
  pedido: IPedido
  getDetalhesPedido: ({
    codigo,
    numeroLoja,
    tabela,
  }: IRequestGetDetalhesPedido) => Promise<void>

  getDetalhesLoja: ({ numeroLoja }: IRequestDetalhesLoja) => Promise<void>
}

export const DetalhesPedidoContext = createContext(
  {} as DetalhesPedidoContextProps,
)

interface IRequestGetDetalhesPedido {
  codigo: string
  numeroLoja: string
  tabela: string
}

interface IRequestDetalhesLoja {
  numeroLoja: string
}

export const DetalhesPedidoProvider = ({ children }: IChildren) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [pedido, setPedido] = useState<IPedido>({} as IPedido)
  const [detalhesLoja, setDetalhesLoja] = useState<unidadeDeNegocios[]>([])

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  const getDetalhesPedido = async ({
    codigo,
    numeroLoja,
    tabela,
  }: IRequestGetDetalhesPedido) => {
    try {
      if (tabela === 'Fech.contas') {
        const { data } = await api.getPedidoEItensFCO(codigo, {
          numeroLoja,
        })

        setPedido(data)
        setIsOpenModal(true)

        return
      }

      const { data } = await api.getPedidoEItens(codigo, {
        numeroLoja,
      })

      setPedido(data)
      setIsOpenModal(true)
    } catch (error) {
      exibirErrorCatch(error)
    }
  }

  const getDetalhesLoja = async ({ numeroLoja }: IRequestDetalhesLoja) => {
    try {
      const params = {
        numeroLoja,
      }

      const {
        data: { unidadeDeNegocios },
      } = await api.getDetalhesLoja(params)

      setDetalhesLoja(unidadeDeNegocios)
    } catch (error) {
      notifyErrorCatch(error)
    }
  }

  return (
    <>
      <ModalDetalhesPedido
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        pedido={pedido}
        unidadeDeNegocios={detalhesLoja}
      />

      <DetalhesPedidoContext.Provider
        value={{
          isOpenModal,
          closeModal,
          openModal,
          pedido,
          getDetalhesPedido,
          getDetalhesLoja,
        }}
      >
        {children}
      </DetalhesPedidoContext.Provider>
    </>
  )
}
