import { SIGNATURE } from 'config/jwt'

import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import filtersReducer, { Filters } from './slices/filters'
import hideTotalsReducer, { HideTotals } from './slices/hideTotals'

export interface StoreReduxProps {
  filters: Filters
  hideTotals: HideTotals
}

const persistConfig = {
  key: SIGNATURE,
  storage,
}

const rootReducer = combineReducers({
  filters: filtersReducer,
  hideTotals: hideTotalsReducer,
})

const persisteReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persisteReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export const persistedStore = persistStore(store)

export default store
