import styled from 'styled-components'

export const ContainerWith2Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
  }
`
