/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

import { IResponseTotalsByTypeOperations } from '@types'

import api from 'api'
import exibirErrorCatch from 'utils/toast-catch-error'

import { MaxCard, Table, Thead } from 'maxscalla-lib'
import { TableEmpty } from 'components/Layout/TableEmpty'
import { Row } from './Row'
import { ContainerWith2Columns } from 'components'
import { maskMoney } from 'utils'
import useHideTotals from 'hooks/reduce/useHideTotals'

export interface IFilterTotalsByTypeOperation {
  numeroLoja?: string
  dataInicial?: string
  dataFinal?: string
  caixa?: string
}

interface ITotalsByTypeOperation {
  filters: IFilterTotalsByTypeOperation
  isDisableFilter?: boolean
  onChangeFilters?: (
    column: string | undefined,
    value: string | undefined,
  ) => void
}

export const TotalsByTypeOperation = ({
  filters,
  isDisableFilter,
  onChangeFilters,
}: ITotalsByTypeOperation) => {
  const { isHideTotals } = useHideTotals()

  const [totalsTypeOperationE, setTotalsTypeOperationE] = useState<
    IResponseTotalsByTypeOperations[]
  >([])
  const [totalsTypeOperationS, setTotalsTypeOperationS] = useState<
    IResponseTotalsByTypeOperations[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getTotaisTipoOperacao = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data: totalsTypeOperations } = await api.totaisPorTipoOperacao(
        filters,
      )

      const totalsTypeOperationsWithNonZeroValue = totalsTypeOperations.filter(
        (item) => item.qtdeLancada !== 0,
      )

      const totalsTypeOperationsE = totalsTypeOperationsWithNonZeroValue.filter(
        (item) => item.flag === 'E',
      )

      const totalsTypeOperationsS = totalsTypeOperationsWithNonZeroValue.filter(
        (item) => item.flag === 'S',
      )

      setTotalsTypeOperationE(totalsTypeOperationsE)
      setTotalsTypeOperationS(totalsTypeOperationsS)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      exibirErrorCatch(error)
    }
  }, [
    filters.dataInicial,
    filters.dataFinal,
    filters.caixa,
    filters.numeroLoja,
  ])

  const total = totalsTypeOperationE.reduce((acc, operation) => {
    const isNegative =
      operation.nameToFilter === 'DevolucaoDeVenda' ||
      operation.nameToFilter === 'ValeRealizadoEFechadoNoMesmoDia'

    const positiveValue =
      operation.valorLancado < 0
        ? operation.valorLancado * -1
        : operation.valorLancado

    if (operation.nameToFilter === 'DIV') {
      return acc
    }

    if (isNegative) {
      return acc - positiveValue
    }

    return acc + operation.valorLancado
  }, 0)

  const title = isHideTotals ? 'Entradas' : `Entradas  - ${maskMoney(total)}`

  useEffect(() => {
    getTotaisTipoOperacao()
  }, [getTotaisTipoOperacao])

  return (
    <ContainerWith2Columns>
      <MaxCard.Container>
        <MaxCard.Header title={title} />
        <MaxCard.Body>
          <Table length={totalsTypeOperationE.length} isDisableMouseTrap>
            <Thead>
              <th>Tipo</th>
              <th>Quantidade</th>
              <th>Valor</th>
              {!isDisableFilter ? <th>Filtrar</th> : null}
            </Thead>
            <tbody>
              {isLoading ? (
                <tr style={{ position: 'relative' }}>
                  <div>
                    <div
                      style={{ position: 'absolute', inset: 0, margin: 'auto' }}
                      className="spinner-border"
                      role="status"
                    />
                  </div>
                  <div />
                </tr>
              ) : (
                <>
                  {!isLoading && totalsTypeOperationE.length ? (
                    <>
                      {totalsTypeOperationE.map((totals, index) => (
                        <Row
                          key={index}
                          index={index}
                          totalsTypeOperation={totals}
                          isDisableFilter={isDisableFilter}
                          onChangeFilters={onChangeFilters}
                        />
                      ))}
                    </>
                  ) : (
                    <TableEmpty
                      colSpan={13}
                      text="Não há operações a serem exibidas!"
                    />
                  )}
                </>
              )}
            </tbody>
          </Table>
        </MaxCard.Body>
      </MaxCard.Container>

      <MaxCard.Container>
        <MaxCard.Header title="Saídas" />
        <MaxCard.Body>
          <Table length={totalsTypeOperationS.length} isDisableMouseTrap>
            <Thead>
              <th>Tipo</th>
              <th>Quantidade</th>
              <th>Valor</th>
              {!isDisableFilter ? <th>Filtrar</th> : null}
            </Thead>
            <tbody>
              {isLoading ? (
                <tr style={{ position: 'relative' }}>
                  <div>
                    <div
                      style={{ position: 'absolute', inset: 0, margin: 'auto' }}
                      className="spinner-border"
                      role="status"
                    />
                  </div>
                  <div />
                </tr>
              ) : (
                <>
                  {totalsTypeOperationS.length ? (
                    <>
                      {totalsTypeOperationS.map((totals, index) => (
                        <Row
                          key={index}
                          index={index}
                          totalsTypeOperation={totals}
                          isDisableFilter={isDisableFilter}
                          onChangeFilters={onChangeFilters}
                        />
                      ))}
                    </>
                  ) : (
                    <TableEmpty
                      colSpan={13}
                      text="Não há operações a serem exibidas!"
                    />
                  )}
                </>
              )}
            </tbody>
          </Table>
        </MaxCard.Body>
      </MaxCard.Container>
    </ContainerWith2Columns>
  )
}
