import { IChildren, IPedido } from '@types'
import api from 'api'
import { ModalDetalhesPedido } from 'pages/Caixas/OperacoesNaoRecebidas/components/ModalDetalhesPedidoNaoRecebidos'
import { useState } from 'react'
import { createContext } from 'use-context-selector'
import exibirErrorCatch from 'utils/toast-catch-error'

interface DetalhesPedidoNaoRecebidoContextProps {
  isOpenModal: boolean
  closeModal: () => void
  openModal: () => void
  pedido: IPedido
  getDetalhesPedidoNaoRecebido: ({
    codigo,
    numeroLoja,
    tabela,
  }: IRequestGetDetalhesPedidoNaoRecebido) => void
}

export const DetalhesPedidoNaoRecebidoContext = createContext(
  {} as DetalhesPedidoNaoRecebidoContextProps,
)

interface IRequestGetDetalhesPedidoNaoRecebido {
  codigo: string
  numeroLoja: string
  tabela: string
}

export const DetalhesPedidoNaoRecebidoProvider = ({ children }: IChildren) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [pedido, setPedido] = useState<IPedido>({} as IPedido)

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  const getDetalhesPedidoNaoRecebido = async ({
    codigo,
    numeroLoja,
    tabela,
  }: IRequestGetDetalhesPedidoNaoRecebido) => {
    try {
      if (tabela === 'Fech.contas') {
        const { data } = await api.getPedidoEItensFCO(codigo, {
          numeroLoja,
        })

        setPedido(data)
        setIsOpenModal(true)

        return
      }

      const { data } = await api.getPedidoEItens(codigo, {
        numeroLoja,
      })

      setPedido(data)
      setIsOpenModal(true)
    } catch (error) {
      exibirErrorCatch(error)
    }
  }

  return (
    <>
      <ModalDetalhesPedido
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        pedido={pedido}
        unidadeDeNegocios={[]}
      />

      <DetalhesPedidoNaoRecebidoContext.Provider
        value={{
          isOpenModal,
          closeModal,
          openModal,
          pedido,
          getDetalhesPedidoNaoRecebido,
        }}
      >
        {children}
      </DetalhesPedidoNaoRecebidoContext.Provider>
    </>
  )
}
