import { useEffect } from 'react'
import moment from 'moment'
import { MESES } from '../utils/constants'

interface Props {
  id: string
  className: string
  data: string
  onChange: (intervalo: any) => void
}

const DATE_PICKER_LOCALE = {
  format: 'DD/MM/YYYY',
  applyLabel: 'Confirmar',
  cancelLabel: 'Cancelar',
  customRangeLabel: 'Selecionar período',
  daysOfWeek: ['Do', 'Se', 'Te', 'Qua', 'Qui', 'Se', 'Sa'],
  monthNames: MESES,
  firstDay: 1,
}

const DateOnlyPicker = ({ id, className, data, onChange }: Props) => {
  useEffect(() => {
    ;($(`#${id}`) as any)
      .daterangepicker(
        {
          locale: DATE_PICKER_LOCALE,
          singleDatePicker: true,
          timePicker: false,
          timePicker24Hour: false,
          startDate: moment(data).format('DD/MM/YYYY'),
          autoApply: true,
        },
        (value: any) => {
          onChange(value.format('YYYY-MM-DD'))
        },
      )
      .focus(() => {
        $('.prev, .next').remove()
        $('.month').attr('colspan', 8)
      })

    $('.drp-selected').hide()
  }, [data, id, onChange])

  return (
    <input
      id={id}
      className={`${className} datePicker`}
      style={{ width: '100%' }}
      type="text"
    />
  )
}

export default DateOnlyPicker
