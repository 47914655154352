import { MaxCard } from 'maxscalla-lib'
import clsx from 'clsx'
import moment from 'moment'
import { Calender, Container } from './styles'
import { getDateFromRange } from 'utils'
import { useCallback, useState } from 'react'

interface YearsCalenderProps {
  isOpen: boolean
  baseYear: string
  changeBaseYear: (value: string) => void
  changeCalender: (x: boolean) => void
}

export const YearsCalender = ({
  isOpen,
  baseYear,
  changeBaseYear,
  changeCalender,
}: YearsCalenderProps) => {
  const [baseToRange, setBaseToRange] = useState(baseYear)

  const getYears = useCallback(() => {
    const years = getDateFromRange(
      moment(baseToRange).subtract(11, 'year').format('YYYY'),
      moment(baseToRange).add(1, 'year').format('YYYY'),
      true,
    )

    return years
  }, [baseToRange])

  return (
    <Container style={{ display: isOpen ? 'inherit' : 'none' }}>
      <MaxCard.Container style={{ boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
        <MaxCard.Body id="years-calender" style={{ padding: 15 }}>
          <div className="d-flex flex-column align-items-center">
            <div
              className="d-flex flex-row gap-30-px align-items-center"
              style={{
                paddingInline: '16px',
                height: '36px',
              }}
            >
              <button
                type="button"
                onClick={() =>
                  setBaseToRange(
                    moment(baseToRange).subtract(12, 'year').format('YYYY'),
                  )
                }
              >
                <i className="fa-solid fa-chevron-left fs-12 cursor-pointer" />
              </button>
              <span className="fw-bold">{`${getYears()[0]} - ${
                getYears()[11]
              }`}</span>
              <button
                type="button"
                onClick={() =>
                  setBaseToRange(
                    moment(baseToRange).add(12, 'year').format('YYYY'),
                  )
                }
              >
                <i className="fa-solid fa-chevron-right fs-12 cursor-pointer" />
              </button>
            </div>
            <Calender className="d-flex flex-column gap-15-px">
              <div className="years">
                {getYears().map((year, i) => (
                  <div
                    key={year}
                    className={clsx(
                      year === baseYear && 'active',
                      'd-flex justify-content-center fs-6',
                    )}
                    onClick={() => {
                      changeBaseYear(year)
                      changeCalender(false)
                    }}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </Calender>
          </div>
        </MaxCard.Body>
      </MaxCard.Container>
    </Container>
  )
}
