const percentageMask = (value: number | string): string => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formattedValue = formatter.format(Number(value) / 100 || 0)
  return formattedValue.replace('.', ',')
}

export default percentageMask
