import { Select } from 'maxscalla-lib'

const statusColumns = [
  { translated: 'Venda', untranslated: 'V' },
  { translated: 'Reserva', untranslated: 'P' },
  { translated: 'Fechamento de contas', untranslated: 'F' },
  { translated: 'Duplicata', untranslated: 'D' },
  { translated: 'Lote', untranslated: 'L' },
  { translated: 'Recebimento parcial', untranslated: 'R' },
]

interface ISelectDocumentoOrigem {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectDocumentoOrigem = ({
  value,
  setValue,
}: ISelectDocumentoOrigem) => {
  return (
    <Select
      name="select-documento-origem"
      value={String(value) || ''}
      focusColor="blue"
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Status" />
      <option hidden>Documento origem</option>
      {statusColumns.map((option) => (
        <option key={option.untranslated} value={option.untranslated}>
          {option.translated}
        </option>
      ))}
    </Select>
  )
}
