import { IChildren } from '@types'
import moment from 'moment'
import { Modalidades } from 'pages/Financeiro/Cartoes'
import { useState } from 'react'

import { createContext } from 'use-context-selector'

interface Data {
  dataInicial: string
  dataFinal: string
}

interface CartoesContextProps {
  filters: Data
  setFilters: React.Dispatch<React.SetStateAction<Data>>
  optionsBandeira: IOptionsBandeira | undefined
  changeOptionsBandeira: (value: IOptionsBandeira) => void
  modalidade: Modalidades
  changeModalidade: (value: Modalidades) => void
}

interface IOptionsBandeira {
  bandeira: string
  bandeiraId: number
}

export const CartoesContext = createContext({} as CartoesContextProps)

export const CartoesProvider = ({ children }: IChildren) => {
  const [filters, setFilters] = useState<Data>({
    dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [optionsBandeira, setOptionsBandeira] = useState<
    IOptionsBandeira | undefined
  >(undefined)

  const [modalidade, setModalidade] = useState<Modalidades>('todos')

  const changeModalidade = (value: Modalidades) => {
    setModalidade(value)
  }

  const changeOptionsBandeira = (value: IOptionsBandeira) => {
    setOptionsBandeira(value)
  }

  return (
    <CartoesContext.Provider
      value={{
        filters,
        setFilters,
        optionsBandeira,
        changeOptionsBandeira,
        modalidade,
        changeModalidade,
      }}
    >
      {children}
    </CartoesContext.Provider>
  )
}
