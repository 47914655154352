export const traducaoFormaPagamentoPelaSigla = (codigo: string): string => {
  switch (codigo) {
    case 'I':
      return '1'
    case 'C':
      return '2'
    case 'D':
      return '3'
    case 'T':
      return '4'
    case 'U':
      return '5'
    case 'O':
      return '6'
    case 'P':
      return '7'
    case 'B':
      return '8'
    case 'V':
      return '9'
    case 'M':
      return '10'
    case 'L':
      return '11'
    case 'X':
      return '12'
    default:
      return '0'
  }
}
