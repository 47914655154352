import { InputMaskMoney } from 'components'
import { useState } from 'react'

interface IInputValor {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const InputValor = ({ value, setValue }: IInputValor) => {
  const formatValueBeforeSet = (value: string) => {
    const formatedValue = value
      .replaceAll(',', '')
      .replaceAll('.', '')
      .replaceAll('R$', '')
      .replaceAll(' ', '')

    return formatedValue.slice(0, -2) + '.' + formatedValue.slice(-2) || ''
  }

  return (
    <InputMaskMoney
      name="select-descricao-operacao"
      id="select-descricao-operacao"
      className="form-control"
      value={String(value) || ''}
      onChange={(e: any) =>
        setValue(formatValueBeforeSet(String(e.target.value)))
      }
      defaultValue={0}
    />
  )
}
