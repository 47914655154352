import moment from 'moment'

interface Props {
  data: string | undefined
  handleChangeDay: (op: string) => void
}

const DayPicker = ({ data, handleChangeDay }: Props) => (
  <div
    data-kt-daterangepicker="true"
    data-kt-daterangepicker-opens="left"
    className="btn btn-sm btn-light d-flex align-items-center px-4"
    data-kt-initialized="1"
    style={{
      position: 'relative',
      width: 215,
      padding: 'calc(0.55rem - 2px) calc(1.25rem + 1px)',
      cursor: 'default',
    }}
  >
    <div
      className="d-flex flex-row gap-10-px w-100"
      style={{ justifyContent: 'space-between' }}
    >
      <button
        type="button"
        className="p-0"
        onClick={() => handleChangeDay('subtracao')}
      >
        <i className="fa-solid fa-chevron-left fs-12 cursor-pointer" />
      </button>
      <div className="text-gray-600 fw-bold">
        {moment(data).format('DD / MMMM')}
      </div>
      <button
        type="button"
        className="p-0"
        onClick={() => handleChangeDay('adicao')}
      >
        <i className="fa-solid fa-chevron-right fs-12 cursor-pointer" />
      </button>
    </div>
  </div>
)

export default DayPicker
