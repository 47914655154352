import { Select } from 'maxscalla-lib'

const colunasTipoOperacao = [
  { translated: 'Abatimento de contas', untranslated: 'RCP' },
  { translated: 'Ajuste de caixa', untranslated: 'AJV' },
  { translated: 'Desconto concedido', untranslated: 'DSC' },
  { translated: 'Devolução de venda', untranslated: 'DevolucaoDeVenda' },
  { translated: 'Digitação manual', untranslated: 'DigitacaoManual' },
  { translated: 'Divergência de fechamento de caixa', untranslated: 'DIV' },
  { translated: 'Entrada de troco', untranslated: 'EntradaDeTroco' },
  { translated: 'Fechamento de contas', untranslated: 'FCO' },
  { translated: 'Recebimento de caixinha', untranslated: 'CXA' },
  { translated: 'Recebimento de lote', untranslated: 'RecebimentoDeLote' },
  { translated: 'Recebimento de título', untranslated: 'DUP' },
  { translated: 'Retirada do caixa', untranslated: 'PAG' },
  { translated: 'Saldo anterior', untranslated: 'SAL' },
  { translated: 'Sangria de caixa', untranslated: 'SangriaDeCaixa' },
  // { translated: 'Troca de cheque', untranslated: 'TCH' },
  {
    translated: 'Troco de fechamento de contas',
    untranslated: 'TrocoDeFechamentoDeContas',
  },
  {
    translated: 'Troco de recebimento de lote',
    untranslated: 'TrocoDeRecebimentoDeLote',
  },
  {
    translated: 'Troco de recebimento de venda',
    untranslated: 'TrocoDeRecebimentoDeVenda',
  },
  {
    translated: 'Troco de retirada de caixa',
    untranslated: 'TrocoDeRetiradaDeCaixa',
  },
  { translated: 'Vale para funcionário', untranslated: 'VAL' },
  {
    translated: 'Vale realizado e fechado no mesmo dia',
    untranslated: 'ValeRealizadoEFechadoNoMesmoDia',
  },
  { translated: 'Venda', untranslated: 'Venda' },
]

interface ISelectTipo {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectTipo = ({ value, setValue }: ISelectTipo) => {
  return (
    <Select
      name="select-descricao-operacao"
      value={String(value) || ''}
      focusColor="blue"
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Tipo da operação" />
      <option hidden>Tipo da operação</option>
      {colunasTipoOperacao.map((option) => (
        <option key={option.untranslated} value={option.untranslated}>
          {option.translated}
        </option>
      ))}
    </Select>
  )
}
