import { ReactElement } from 'react'
import { Doughnut } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

interface Props {
  labels: string[]
  dados: number[]
  labelColors: string[]
  options: any
}

const DoughnutChart = ({
  labels,
  dados,
  labelColors,
  options,
}: Props): ReactElement => {
  const data = {
    labels,
    datasets: [
      {
        radius: '90%',
        data: dados,
        backgroundColor: labelColors,
        borderWidth: 0,
      },
    ],
  }

  return <Doughnut data={data} options={options} width="100%" />
}

export default DoughnutChart
