export const traducaoFormaPagamentoPeloCodigo = (tipoSelecionado: string) => {
  switch (tipoSelecionado) {
    case '0':
      return 'Nenhuma'
    case '1':
      return 'Dinheiro'
    case '2':
      return 'Cheque'
    case '3':
      return 'Boleto'
    case '4':
      return 'Cartão crédito'
    case '5':
      return 'Cartão débito'
    case '6':
      return 'Diversas'
    case '7':
      return 'Vale'
    case '8':
      return 'Depósito'
    case '9':
      return 'Voucher'
    case '10':
      return 'Link de pagamento'
    case '11':
      return 'IFood'
    case '12':
      return 'Pix'
    default:
      return 'Diversas'
  }
}
