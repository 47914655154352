import moneyMask from 'utils/masks/mask-money'

const buildOptionsHorizontalBarChart = (onClick: (x: any) => void) => {
  const option = {
    indexAxis: 'y' as const,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          family: 'Inter, san-serif',
          weight: 300,
        },
        formatter: function (value: number) {
          return moneyMask(value)
        },
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,

        external(context: {
          tooltip: any
          chart: { canvas: { getBoundingClientRect: () => any } }
        }) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip')

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
            tooltipEl.innerHTML = '<table></table>'
            document.body.appendChild(tooltipEl)
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0'
            return
          }

          // Set Text
          if (tooltipModel.body) {
            const title = tooltipModel.title || ['']
            const body = tooltipModel.body[0].lines

            let innerHtml = '<thead>'
            innerHtml += `<tr><th class="tooltip-balao-title">${title[0]}</th></tr>`
            innerHtml += '</thead><tbody>'
            innerHtml += `<tr><td class="tooltip-balao-body">R$ ${body[0]}</td></tr>`
            innerHtml += '</tbody>'

            const tableRoot = tooltipEl.querySelector('table')

            if (!tableRoot) return

            tableRoot.innerHTML = innerHtml
          }

          const position = context.chart.canvas.getBoundingClientRect()

          // Display, position, and set styles for font
          tooltipEl.className = 'tooltip-balao'

          tooltipEl.style.opacity = '1'
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.boxShadow = '2px 2px 3px rgb(0, 0, 0, 0.7)'
          tooltipEl.style.backgroundColor = 'white'
          tooltipEl.style.padding = '5px 10px'
          tooltipEl.style.pointerEvents = 'none'
          tooltipEl.style.textAlign = 'center'
          tooltipEl.style.borderRadius = '5px'

          tooltipEl.style.left = `${
            position.left + window.pageXOffset + tooltipModel.caretX / 2 - 22
          }px`
          tooltipEl.style.top = `${
            position.top + window.pageYOffset + tooltipModel.caretY - 60
          }px`
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 30,
        top: 30,
      },
    },
    scales: {
      y: {
        barPercentage: 0.2,
        ticks: {
          font: {
            size: 13,
            family: 'Inter, san-serif',
            weight: 300,
          },
        },
      },
      x: {
        beginAtZero: true,
        grace: '35%',
        ticks: {
          font: {
            size: 13,
            family: 'Inter, san-serif',
            weight: 300,
          },
        },
      },
    },
    // elements: {
    //   bar: {
    //     borderSkipped: false,
    //   },
    // },
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    onClick,
  }

  return option
}

export default buildOptionsHorizontalBarChart
