import { InputHTMLAttributes, useEffect } from 'react'
import { getInputById } from 'utils'
import VMasker from 'vanilla-masker'

type InputProps = InputHTMLAttributes<HTMLInputElement>

export const InputMaskDate = ({ ...rest }: InputProps) => {
  useEffect(() => {
    const input = getInputById(rest.id || '')
    VMasker(input).maskPattern('99/99/9999')
  }, [rest.id, rest.value])

  return <input {...rest} type="text" maxLength={10} />
}
