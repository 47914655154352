import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export interface Filters {
  pagina: number
  quantidade: number
  dataInicial: string
  dataFinal: string
  numeroLoja: string
  diaSelecionado: string
}

const slice = createSlice({
  name: 'filters',
  initialState: {
    pagina: 1,
    quantidade: 9999,
    dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().endOf('month').format('YYYY-MM-DD'),
    numeroLoja: '',
    diaSelecionado: moment().format('YYYY-MM-DD'),
  },
  reducers: {
    setNumeroLoja(state: Filters, action) {
      return { ...state, numeroLoja: action.payload }
    },
    setFilters(state: Filters, action) {
      return { ...state, dataInicial: action.payload }
    },
  },
})

export const { setFilters, setNumeroLoja } = slice.actions

export default slice.reducer
