import styled from 'styled-components'

export const ContainerTable = styled.div`
  flex: 1 1 auto;
  height: 0;

  border-radius: 4px;

  .table-responsive {
    flex-shrink: 1;
    overflow-y: auto;
    height: 100%;

    thead {
      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 8;
      }
    }
  }
`
