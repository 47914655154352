/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react'
import { Form } from '@unform/web'

import { notifyWarning } from 'utils'
import { IQueryListaOperacoes } from '@types'

import { useContextSelector } from 'use-context-selector'
import { FiltersContext } from 'contexts/filtersContext'

import { Container } from './styles'
import { Button, getColor, Input, Select } from 'maxscalla-lib'

import {
  SelectTipo,
  SelectFormaPagamento,
  SelectOperacao,
  InputValor,
  InputData,
  SelectStatus,
  SelectFCOorPED,
  SelectDocumentoOrigem,
  InputNumber,
  SelectModalidade,
  SelectBandeira,
  SelectAdministradora,
} from './components'

interface Column {
  name: string
  value: string
  type?: string
}

interface FiltersProps {
  columns?: Column[]
  filters?: IQueryListaOperacoes
  hasQuantidade?: boolean
  showEstorno?: boolean
  hasStatus?: boolean
  changeEstorno?: () => void
  onChangeQuantidade?: (q: number) => void
  onChangeFilters: (c: string | undefined, v: string | undefined) => void
  cleanFunction?: () => void
  defaultColumn?: string
}

interface ColumnProps {
  type?: string
  value: string
}

export const Filters = ({
  columns,
  filters,
  hasQuantidade,
  showEstorno,
  hasStatus,
  changeEstorno,
  onChangeFilters,
  onChangeQuantidade,
  cleanFunction,
  defaultColumn,
}: FiltersProps): ReactElement => {
  const { column, value, setColumn, setValue, cleanFilters } =
    useContextSelector(FiltersContext, (context) => {
      return {
        column: context.column,
        value: context.value,
        setColumn: context.changeColumn,
        setValue: context.changeValue,
        cleanFilters: context.cleanFilters,
      }
    })

  const [quantidade, setQuantidade] = useState(10)

  const changeValue = (newValue: string) => {
    setValue(newValue)
  }

  const onSubmit = () => {
    cleanFunction && cleanFunction()

    if (columns && !column.value) {
      notifyWarning('Selecione uma coluna para pesquisar')
      return
    }

    if (!value) {
      notifyWarning('Digite algum filtro para pesquisar')
      return
    }

    onChangeFilters(column.value, String(value))
  }

  const cleanFiltros = () => {
    setColumn({ value: defaultColumn! } as ColumnProps)
    setValue(undefined)
    onChangeFilters(undefined, undefined)

    cleanFunction && cleanFunction()
  }

  const handleChangeQuantidade = (q: number) => {
    if (!onChangeQuantidade) return

    setQuantidade(q)
    onChangeQuantidade(q)
  }

  const fetchCustomInputFromColumn = () => {
    switch (column.type) {
      case 'tipo':
        return <SelectTipo value={value} setValue={changeValue} />
      case 'formaPagamento':
        return <SelectFormaPagamento value={value} setValue={changeValue} />
      case 'operacao':
        return <SelectOperacao value={value} setValue={changeValue} />
      case 'valor':
        return <InputValor value={value} setValue={changeValue} />
      case 'data':
        return <InputData value={value} setValue={changeValue} />
      case 'status':
        return <SelectStatus value={value} setValue={changeValue} />
      case 'FCOorPED':
        return <SelectFCOorPED value={value} setValue={changeValue} />
      case 'docOrigem':
        return <SelectDocumentoOrigem value={value} setValue={changeValue} />
      case 'number':
        return <InputNumber value={value} setValue={changeValue} />
      case 'modalidade':
        return <SelectModalidade value={value} setValue={changeValue} />
      case 'bandeira':
        return <SelectBandeira value={value} setValue={changeValue} />
      case 'administradora':
        return <SelectAdministradora value={value} setValue={changeValue} />
      default:
        return (
          <Input
            id="filter-input"
            type="search"
            name="value"
            placeholder="Digite aqui..."
            focusColor={getColor('blue')}
            value={value || ''}
            onChange={(e: any) => setValue(e.target.value)}
            style={{ minWidth: 200 }}
          />
        )
    }
  }

  const handleSetColumn = (targetValue: string) => {
    const type = targetValue.split(',')[0]
    const value = targetValue.split(',')[1]

    setValue('')
    setColumn({ value, type })
  }

  useEffect(() => {
    cleanFilters()
  }, [])

  useEffect(() => {
    if (defaultColumn) {
      setColumn({ value: defaultColumn })
    }
  }, [])

  return (
    <Container className="row justify-between w-100 m-0 gap-10-px d-flex m-0">
      <Form
        onSubmit={onSubmit}
        className="text-left p-0 d-flex flex-row gap-10-px w-mobile-100 flex-wrap justify-content-center w-100"
      >
        {hasQuantidade ? (
          <Select
            name="length-table"
            style={{ width: 'fit-content' }}
            value={quantidade}
            focusColor={getColor('blue')}
            onChange={(e: any) =>
              handleChangeQuantidade(Number(e.target.value))
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
        ) : null}
        {columns ? (
          <Select
            id="columns-filter"
            name="value"
            style={{ width: 'fit-content' }}
            value={[column.type as string, column.value] || ''}
            focusColor={getColor('blue')}
            onChange={(e: any) => handleSetColumn(e.target.value)}
          >
            <optgroup label="Selecione uma coluna" />
            <option hidden>Selecione uma coluna</option>
            {columns.map((c) => (
              <option key={c.name} value={[c.type as string, c.value]}>
                {c.name}
              </option>
            ))}
          </Select>
        ) : null}
        <div className="dataTables_filter w-min-200px w-mobile-100 flex-1">
          {fetchCustomInputFromColumn()}
        </div>
        {changeEstorno && hasStatus ? (
          <button
            type="button"
            className="form-control"
            style={{
              width: 'fit-content',
            }}
            onClick={changeEstorno}
          >
            {showEstorno ? 'Exibir estorno' : 'Ocultar estorno'}
          </button>
        ) : null}
        <div
          className="form-group w-mobile-100 gap-10-px align-items-center"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <Button type="submit" bgColor="lightGreen">
            Filtrar
          </Button>
          <Button type="button" bgColor="blue" onClick={cleanFiltros}>
            Limpar
          </Button>
        </div>
      </Form>
    </Container>
  )
}

Filters.displayName = 'Filters'

Filters.defaultProps = {
  hasQuantidade: false,
  hasOpcoesPesquisa: false,
  columns: undefined,
  filters: undefined,
  onChangeQuantidade: undefined,
  onChangeOpcaoPesquisa: undefined,
}
