import VMasker from 'vanilla-masker'
import React, { useEffect, InputHTMLAttributes, ReactElement } from 'react'
import { getInputById } from '../../utils'

type InputProps = InputHTMLAttributes<HTMLInputElement>

const InputMaskMoney: React.FC<InputProps> = ({ ...rest }): ReactElement => {
  useEffect(() => {
    const input = getInputById(rest.id || '')
    VMasker(input).maskMoney({
      unit: 'R$',
    })
  }, [rest.id, rest.value])

  return <input {...rest} />
}

export default InputMaskMoney
