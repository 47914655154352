/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useCallback, useState } from 'react'

import { IFilters, ResponseMovimentoVendas } from '@types'

import api from 'api'
import { loading, notifyErrorCatch } from 'utils'

import { createContext } from 'use-context-selector'

interface IMovimentoVendasContextProps {
  children: ReactNode
}

export type Status =
  | 'todasOperacoes'
  | 'vendasReservarPrazosDevolucoes'
  | 'vendas'
  | 'reservas'
  | 'devolucoes'
  | 'orcamentos'
  | 'cancelamentos'
  | 'prazosAberto'
  | 'prazosDevolucosAberto'
  | 'prazosProcessados'
  | 'prazosDevolucoesProcessadas'
  | 'prazosAbertoProcessados'
  | 'prazosDevolucoesAberto'

interface MovimentoVendasContextProps {
  getMovimentoVendas: (filters: IFilters) => Promise<void>
  movimentoVendas: ResponseMovimentoVendas
  status: Status
  changeStatus: (value: Status) => void
}

export const MovimentoVendasContext = createContext(
  {} as MovimentoVendasContextProps,
)

export const MovimentoVendasProvider = ({
  children,
}: IMovimentoVendasContextProps) => {
  const [movimentoVendas, setMovimentoVendas] =
    useState<ResponseMovimentoVendas>({
      dayValues: [],
      diasNaoUteis: [],
      diasUteis: 0,
      mediaDiaria: 0,
      projecaoMensal: 0,
      totaisDias: 0,
      total: 0,
      diasCorridos: 0,
    })
  const [status, setStatus] = useState<Status>('todasOperacoes')

  const changeStatus = (value: Status) => {
    setStatus(value)
  }

  const getMovimentoVendas = useCallback(async (filters: IFilters) => {
    try {
      loading.show()

      const params = {
        dataInicial: String(filters.dataInicial),
        dataFinal: String(filters.dataFinal),
        numeroLoja: filters.numeroLoja,
      }

      const { data } = await api.getMovimentoVendas(params)

      setMovimentoVendas(data)

      loading.hide()
    } catch (error) {
      loading.hide()
      notifyErrorCatch(
        error,
        'Algo deu errado ao buscar as informações sobre os caixas',
      )
    }
  }, [])

  const values = { getMovimentoVendas, movimentoVendas, status, changeStatus }

  return (
    <MovimentoVendasContext.Provider value={values}>
      {children}
    </MovimentoVendasContext.Provider>
  )
}
