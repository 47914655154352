import { ClientesByHistoricoDeCompra, IFilters } from '@types'
import api from 'api'
import moment from 'moment'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import { notifyErrorCatch } from 'utils'
import { MainContext } from './mainContext'

export type SwithValueGrafico = 'Exibir valores' | 'Exibir quantidades'
type RangeDays =
  | 'last30days'
  | 'last90days'
  | 'last180days'
  | 'last360days'
  | 'last360plus'

interface IClientesHistoricoCompraContextProps {
  children: ReactNode
}

interface ClientesHistoricoCompraContextProps {
  swithValueGrafico: SwithValueGrafico
  changeSwithValueGrafico: (value: SwithValueGrafico) => void
  rangeDays: RangeDays
  changeRangeDays: (value: RangeDays) => void
  currentClient: ClientesByHistoricoDeCompra | null
  handleCurrentClient: () => void
  filters: IFilters
  changeFilters: (value: IFilters) => void
  clientes: ClientesByHistoricoDeCompra[]
  clientesDB: ClientesByHistoricoDeCompra[]
  changeClientes: (value: ClientesByHistoricoDeCompra[]) => void
  getClientesByHistoricoCompra: () => Promise<void>
  totalPages: number
  isLoading: boolean
}

export const ClientesHistoricoCompraContext = createContext(
  {} as ClientesHistoricoCompraContextProps,
)

const defaultFilters = {
  dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
  dataFinal: moment().endOf('month').format('YYYY-MM-DD'),
  pagina: 1,
  quantidade: 10,
  column: '',
  value: '',
  order: 'ASC',
  columnToOrder: 'rank',
} as IFilters

export const ClientesHistoricoCompraProvider = ({
  children,
}: IClientesHistoricoCompraContextProps) => {
  const [filters, setFilters] = useState<IFilters>(defaultFilters)
  const [clientes, setClientes] = useState<ClientesByHistoricoDeCompra[]>([])
  const [clientesDB, setClientesDB] = useState<ClientesByHistoricoDeCompra[]>(
    [],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)

  const [currentClient, setCurrentClient] =
    useState<ClientesByHistoricoDeCompra | null>(null)
  const [swithValueGrafico, setSwithValueGrafico] =
    useState<SwithValueGrafico>('Exibir valores')
  const [rangeDays, setRangeDays] = useState<RangeDays>('last30days')

  const numeroLoja = useContextSelector(MainContext, (context) =>
    Number(context.filters.numeroLoja),
  )

  const changeClientes = (value: ClientesByHistoricoDeCompra[]) => {
    setClientes(value)
  }

  const changeSwithValueGrafico = (value: SwithValueGrafico) => {
    setSwithValueGrafico(value)
  }

  const changeRangeDays = (value: RangeDays) => {
    setRangeDays(value)
  }

  const changeFilters = (value: IFilters) => {
    setFilters((prev) => {
      return { ...prev, ...value }
    })
  }

  const handleCurrentClient = () => {
    setTimeout(() => {
      const codigoSelectedRow = $('.selected-row').attr('data-codigo')

      if (!codigoSelectedRow) return

      const currentClient = clientesDB.find(
        (cliente) => cliente.codigo === codigoSelectedRow,
      )

      if (currentClient) {
        setCurrentClient(currentClient)
      }
    }, 100)
  }

  const getClientesByHistoricoCompra = useCallback(async () => {
    try {
      if (isLoading) return
      setIsLoading(true)

      const { data } = await api.getClientesByHistoricoDeCompra({
        baseDate: moment().format('YYYY-MM-DD'),
        rangeType: rangeDays,
        numeroLoja,
      })

      const totalPages = Math.ceil(data.length / Number(filters.quantidade))
      setTotalPages(totalPages)

      setClientesDB(data)

      setCurrentClient(data[0])

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notifyErrorCatch(error)
    }
  }, [rangeDays, numeroLoja])

  const updateTotalPaginas = () => {
    const totalPaginas = Math.ceil(
      clientesDB.length / Number(filters.quantidade),
    )

    setTotalPages(totalPaginas)
  }

  useEffect(() => {
    updateTotalPaginas()
  }, [filters.quantidade])

  useEffect(() => {
    handleCurrentClient()
  }, [totalPages, filters, rangeDays])

  useEffect(() => {
    changeFilters({ pagina: 1 })
  }, [rangeDays])

  const values = {
    swithValueGrafico,
    changeSwithValueGrafico,
    rangeDays,
    changeRangeDays,
    currentClient,
    handleCurrentClient,
    filters,
    changeFilters,
    clientes,
    clientesDB,
    changeClientes,
    getClientesByHistoricoCompra,
    totalPages,
    isLoading,
  }

  return (
    <ClientesHistoricoCompraContext.Provider value={values}>
      {children}
    </ClientesHistoricoCompraContext.Provider>
  )
}
