import { IResponseTotalsByTypeOperations } from '@types'
import { Tr } from 'maxscalla-lib'
import moneyMask from 'utils/masks/mask-money'

interface IRow {
  totalsTypeOperation: IResponseTotalsByTypeOperations
  index: number
  isDisableFilter?: boolean
  onChangeFilters?: (
    column: string | undefined,
    value: string | undefined,
  ) => void
}

export const Row = ({
  totalsTypeOperation,
  index,
  isDisableFilter,
  onChangeFilters,
}: IRow) => {
  const isNegative =
    (totalsTypeOperation.nameToFilter === 'DevolucaoDeVenda' ||
      totalsTypeOperation.nameToFilter === 'ValeRealizadoEFechadoNoMesmoDia') &&
    totalsTypeOperation.flag === 'E'

  return (
    <Tr index={index} style={{ lineHeight: '40px' }}>
      <td>{totalsTypeOperation.tipo}</td>
      <td>{totalsTypeOperation.qtdeLancada}</td>
      {isNegative ? (
        <td style={{ color: 'var(--kt-danger)' }}>
          {moneyMask(
            totalsTypeOperation.valorLancado > 0
              ? totalsTypeOperation.valorLancado * -1
              : totalsTypeOperation.valorLancado,
          )}
        </td>
      ) : (
        <td>{moneyMask(totalsTypeOperation.valorLancado)}</td>
      )}
      {!isDisableFilter ? (
        <td>
          <button
            style={{ height: 20 }}
            className="max-btn cs-btn-green btn-sm text-white d-flex"
            onClick={() =>
              onChangeFilters &&
              onChangeFilters('tipo', totalsTypeOperation.nameToFilter)
            }
          >
            <i className="fa-regular fa-filter" />
          </button>
        </td>
      ) : null}
    </Tr>
  )
}
