import { Select } from 'maxscalla-lib'

interface ISelectOperacao {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectFCOorPED = ({ value, setValue }: ISelectOperacao) => {
  return (
    <Select
      name="select-FCOorPED"
      value={String(value) || ''}
      focusColor={'blue'}
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Tipo da operação" />
      <option hidden>Tipo da operação</option>
      <option value="Reserva">Reserva</option>
      <option value="Fech.contas">Fech.contas</option>
    </Select>
  )
}
