export const traduzir = (tipoSelecionado: string): string => {
  switch (tipoSelecionado) {
    case 'CXA':
      return 'Recebimento de caixinha'
    case 'AJV':
      return 'Ajuste de caixa'
    case 'DSC':
      return 'Desconto concedido'
    case 'DIV':
      return 'Divergência de fechamento de caixa'
    case 'DUP':
      return 'Recebimento de título'
    case 'FCO':
      return 'Fechamento de contas'
    case 'LOT':
      return 'Recebimento de lote'
    case 'PAG':
      return 'Retirada do caixa'
    case 'PED':
      return 'Venda'
    case 'SAL':
      return 'Saldo anterior'
    case 'RCP':
      return 'Abatimento de contas'
    case 'TRC':
      return 'Troco de retirada de caixa'
    case 'TCH':
      return 'Troca de cheque'
    case 'TRF' || 'TBB':
      return 'Transferência entre contas'
    case 'VAL':
      return 'Vale para funcionário'
    default:
      return 'Digitação manual'
  }
}

const complexTranslations = (
  tipoSelecionado: string,
  historico = '',
  operacao?: string,
): string | null => {
  const isESTORNO = historico.toUpperCase().indexOf('(ESTORNO') !== -1
  const isTrocaCheque =
    historico.toUpperCase().indexOf('TROCA DE CHEQUE') !== -1
  const isTROCO = historico.toUpperCase().indexOf('(TROCO') !== -1
  const withTRANSFERENCIA =
    historico.toUpperCase().indexOf('TRANSFERENCIA') !== -1
  const withRECEBIMENTO_DE_TROCO =
    historico.toUpperCase().indexOf('RECEBIMENTO DE TROCO') !== -1
  const withENTRADA_DE_TROCO =
    historico.toUpperCase().indexOf('ENTRADA DE TROCO') !== -1
  const withFECHAMENTO_DE_CONTAS =
    historico.toUpperCase().indexOf('FECH.CONTAS') !== -1

  if (tipoSelecionado === 'LOT') {
    if (isTrocaCheque) {
      return 'Troca de cheque' // --
    }

    if (isTROCO) {
      return 'Troco de recebimento de lote'
    }
  }

  if (tipoSelecionado === 'TRC') {
    if (withTRANSFERENCIA) {
      return 'Entrada de troco'
    }
    if (withENTRADA_DE_TROCO) {
      return 'Troco de retirada de caixa' // --
    }
  }

  if (tipoSelecionado === 'TRF') {
    if (operacao === '-') {
      return 'Sangria de caixa'
    }

    if (withRECEBIMENTO_DE_TROCO) {
      return 'Troco de retirada de caixa' // --
    }
  }

  if (tipoSelecionado === 'PED') {
    if (withFECHAMENTO_DE_CONTAS) {
      return 'Vale realizado e fechado no mesmo dia'
    }

    if (isTROCO) {
      return 'Troco de recebimento de venda'
    }

    if ((operacao === '-' && !isESTORNO) || (operacao === '+' && isESTORNO)) {
      return 'Devolução de venda'
    }
  }

  if (tipoSelecionado === 'FCO' && operacao === '-' && isTROCO) {
    return 'Troco de fechamento de contas'
  }

  return null
}

export const traducaoTipoOperacao = (
  tipoSelecionado: string,
  historico = '',
  operacao?: string,
) => {
  const isEstorno = historico.toUpperCase().indexOf('(ESTORNO)') !== -1

  let traduzido = complexTranslations(tipoSelecionado, historico, operacao)

  if (traduzido === null) {
    traduzido = traduzir(tipoSelecionado)
  }

  if (isEstorno) {
    return traduzido + '(ESTORNO)'
  }

  return traduzido
}

export const traducaoFormaPagamento = (tipoSelecionado: string) => {
  switch (tipoSelecionado) {
    case '1':
      return 'Dinheiro'
    case '2':
      return 'Cheque'
    case '3':
      return 'Cartão de Crédito'
    case '4':
      return 'Cartão de Débito'
    case '5':
      return 'Duplicata'
    case '6':
      return 'Financiamento'
    case '7':
      return 'Vale'
    case '8':
      return 'Troco'
    case '9':
      return 'Crédto'
    case '10':
      return 'Cartão de Crédto'
    case '34':
      return 'Cartão'
    case 'A':
      return 'Troco Disp.'
    case 'B':
      return 'Débito'
    case 'C':
      return 'Baixa Automática'
    case 'D':
      return 'Estorno'
    case 'E':
      return 'Lote'
    case 'F':
      return 'Depósito Bancário'
    case 'G':
      return 'Cupom de Desconto'
    case 'H':
      return 'Gorjeta'
    case 'I':
      return 'Transferência Bancária'
    case 'J':
      return 'Desconto'
    case 'K':
      return 'Depósito'
    case 'L':
      return 'Ifood'
    case 'M':
      return 'Link de Pagamento'
    case 'V':
      return 'Voucher'
    case 'X':
      return 'Pix'
    default:
      return tipoSelecionado
  }
}

export const statusPedido = {
  '': 'Não selecionado',
  SepaP: 'Separado parcial',
  Entre: 'Entregue',
  EParc: 'Entrega parcial',
  Pend: 'Pendente',
  Prazo: 'Prazo',
  Cupom: 'Cupom',
  Orçam: 'Orçamento',
  Oram: 'Orçamento',
  Reser: 'Reservado',
  Cance: 'Cancelado',
  PrazP: 'À Prazo',
  DevPz: 'À Prazo z',
  Devol: 'Devolução',
  Venda: 'Venda',
  Separ: 'Separado',
}
