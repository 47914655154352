import styled from 'styled-components'

export const CloseModalX = styled.i`
  border: 0;
  background: transparent;
  cursor: pointer;

  font-size: 24px !important;
  color: var(--lightGray);

  transition: filter(0.2s);
  filter: brightness(0.8);

  &:hover {
    filter: brightness(0.5);
  }
`
