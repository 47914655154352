import { useSelector } from 'react-redux'
import { StoreReduxProps } from 'storeRedux'
import { HideTotals } from 'storeRedux/slices/hideTotals'

const useHideTotals = (): HideTotals => {
  const hideTotals = useSelector((state: StoreReduxProps) => state.hideTotals)

  return hideTotals
}

export default useHideTotals
