import { ButtonHTMLAttributes, ReactElement } from 'react'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading: boolean
  titleLoading: string
}

const ButtonLoading = ({
  type = 'button',
  children,
  loading,
  titleLoading,
  ...rest
}: ButtonProps): ReactElement => (
  <>
    {loading ? (
      <button disabled type={type} {...rest}>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span>{titleLoading}</span>
      </button>
    ) : (
      <button type={type} {...rest}>
        {children}
      </button>
    )}
  </>
)

export default ButtonLoading
