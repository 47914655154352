export const traducaoStatusOperacao = (tipoSelecionado: string) => {
  switch (tipoSelecionado) {
    case 'Or‡am':
      return 'ORÇAMENTO'
    case 'OrÎam':
      return 'ORÇAMENTO'
    case 'Orcam':
      return 'ORÇAMENTO'
    case 'Reser':
      return 'RESERVA'
    case 'Prazo':
      return 'PRAZO'
    case 'PrazP':
      return 'PRAZO PAGO'
    case 'Venda':
      return 'VENDA'
    case 'Devol':
      return 'DEVOLUÇÃO'
    case 'DevoP':
      return 'DEVOLUÇÃO PRAZO'
    case 'DevPz':
      return 'DEVOLUÇÃO PRAZO'
    case 'Cance':
      return 'OPERAÇÃO CANCELADA'
    default:
      return ''
  }
}
