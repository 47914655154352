import { SideBar as MaxSideBar } from 'maxscalla-lib'
import { IItems } from 'maxscalla-lib/dist/@types'
import { useLocation, NavLink } from 'react-router-dom'
import useAuthentication from '../hooks/useAuthentication'

export const SideBar = () => {
  const { sair } = useAuthentication()
  const { pathname } = useLocation()

  const handleActive = (page: string): boolean => {
    const url = String(pathname)

    if (url.includes(page)) return true

    return false
  }

  const logo = window.location.href.includes('gestor')
    ? '/images/logo-cs-branco.png'
    : '/images/logo-controlshop.png'

  const isDev = window.location.href.includes('local')
  const isGestor = window.location.href.includes('gestor')

  const itemsSideBarGestor = [
    {
      icon: 'fa-duotone fa-chart-user',
      isActive: handleActive('demonstrativos'),
      name: 'Demonstrativos',
      title: 'Demonstrativos',
      subItems: [
        {
          name: 'Dashboards comerciais',
          pathTo: '/demonstrativos/comercial-dashboards',
        },
        {
          name: 'Top vendas',
          pathTo: '/demonstrativos/top-vendas',
        },
        {
          name: 'Comparativo anual de vendas',
          pathTo: '/demonstrativos/comparativo-de-vendas',
        },
        {
          name: 'Clientes histórico de compras',
          pathTo: '/demonstrativos/cliente-historico-compras',
        },
        {
          name: 'Cartões',
          pathTo: '/demonstrativos/cartoes',
        },
      ],
    },
    {
      icon: `fa-duotone fa-bullhorn`,
      isActive: handleActive('comercial/'),
      name: 'Comercial',
      title: 'Comercial',
      subItems: [
        {
          name: 'Orçamentos não convertidos',
          pathTo: '/comercial/orcamentos-nao-convertidos',
        },
      ],
    },
    {
      icon: 'fa-cash-register',
      isActive: handleActive('caixas'),
      name: 'Caixas',
      title: 'Caixa',
      subItems: [
        {
          name: 'Detalhamento',
          pathTo: '/caixas/detalhamento',
        },
        {
          name: 'Operações não recebidas',
          pathTo: '/caixas/operacoes-nao-recebidas',
        },
        {
          name: 'Fechamentos de caixa',
          pathTo: '/caixas/fechamentos-de-caixa',
        },
      ],
    },
    {
      icon: 'fa-arrow-right-from-bracket',
      onClick: sair,
      name: 'Sair',
      title: 'Sair',
    },
  ] as IItems[]

  const itemsSideBarCS = [
    {
      icon: `fa-duotone fa-bullhorn`,
      isActive: handleActive('comercial/'),
      name: 'Comercial',
      title: 'Comercial',
      subItems: [
        {
          name: 'Operações comerciais',
          pathTo: '/comercial/operacoes',
        },
        {
          name: 'Fechamento de contas',
          pathTo: '/comercial/fechamentos-de-contas',
        },
        {
          name: 'Orçamentos não convertidos',
          pathTo: '/comercial/orcamentos-nao-convertidos',
        },
        {
          name: 'Follow-ups de operações',
          pathTo: '/comercial/followups-de-operacoes',
        },
      ],
    },
    {
      icon: 'fa-duotone fa-truck',
      isActive: handleActive('expedicao/'),
      name: 'Expedição',
      title: 'Expedição',
      subItems: [
        {
          name: 'Transferências de mercadorias',
          pathTo: '/expedicao/transferencias-de-mercadorias',
        },
        {
          name: 'Gestão de entregas',
          pathTo: '/expedicao/gestao-de-entregas',
        },
      ],
    },
    {
      icon: 'fa-duotone fa-basket-shopping',
      isActive: handleActive('compras/'),
      name: 'Compras',
      title: 'Compras',
      subItems: [
        {
          name: 'Pedidos de compra',
          pathTo: '/compras/pedidos-de-compra',
        },
        {
          name: 'Notas de entrada',
          pathTo: '/compras/notas-de-entrada',
        },
        {
          name: 'Cotações de compras',
          pathTo: '/compras/cotacoes-de-compras',
        },
      ],
    },
    {
      icon: 'fa-cash-register',
      isActive: handleActive('caixas'),
      name: 'Caixas',
      title: 'Caixa',
      subItems: [
        {
          name: 'Detalhamento',
          pathTo: '/caixas/detalhamento',
        },
        {
          name: 'Operações não recebidas',
          pathTo: '/caixas/operacoes-nao-recebidas',
        },
        {
          name: 'Fechamentos de caixa',
          pathTo: '/caixas/fechamentos-de-caixa',
        },
        {
          name: 'Abatimento de contas',
          pathTo: '/caixas/abatimento-de-contas',
        },
        {
          name: 'Lotes de recebimento',
          pathTo: '/caixas/lotes-de-recebimento',
        },
      ],
    },
    // {
    //   icon: 'fa-credit-card',
    //   isActive: handleActive('cartoes/'),
    //   name: 'Cartões',
    //   title: 'Cartões',
    //   subItems: []
    // },
    {
      icon: `fa-duotone fa-file-invoice-dollar`,
      isActive: handleActive('financeiro'),
      name: 'Financeiro',
      title: 'Financeiro',
      subItems: [
        {
          name: 'Duplicatas a receber',
          pathTo: '/financeiro/duplicatas-a-receber',
        },
        {
          name: 'Cheques recebidos',
          pathTo: '/financeiro/cheques-recebidos',
        },
        {
          name: 'Contas a pagar',
          pathTo: '/financeiro/contas-a-pagar ',
        },
        {
          name: 'Cartões',
          pathTo: '/financeiro/cartoes',
        },
      ],
    },
    {
      icon: `fa-duotone fa-bag-shopping`,
      isActive: handleActive('produtos'),
      name: 'Produtos',
      title: 'Produtos',
      subItems: [
        {
          name: 'Cadastro de produtos',
          pathTo: '/produtos/cadastro-de-produtos',
        },
        {
          name: 'Embalagens de produtos',
          pathTo: '/produtos/embalagens-de-produtos',
        },
        {
          name: 'Grupos de produtos',
          pathTo: '/produtos/grupos-de-produtos',
        },
        {
          name: 'Departamentos de produtos',
          pathTo: '/produtos/departamentos-de-produtos',
        },
        {
          name: 'Categorias de produtos',
          pathTo: '/produtos/categorias-de-produtos',
        },
        {
          name: 'Fabricantes',
          pathTo: '/produtos/fabricantes',
        },
      ],
    },
    {
      icon: `fa-duotone fa-file-lines`,
      isActive: handleActive('fiscal'),
      name: 'Fiscal',
      title: 'Fiscal',
      subItems: [
        {
          name: 'Notas fiscais',
          pathTo: '/fiscal/notas-fiscais',
        },
        {
          name: 'Cupons fiscais emitidos',
          pathTo: '/fiscal/cupons-fiscais-eletronicos-emitidos',
        },
      ],
    },
    {
      icon: `fa-duotone fa-folders`,
      isActive: handleActive('cadastro/'),
      name: 'Cadastro',
      title: 'Cadastro',
      subItems: [
        {
          name: 'Clientes constantes',
          pathTo: '/cadastro/clientes-constantes',
        },
        {
          name: 'Clientes esporádicos',
          pathTo: '/cadastro/clientes-esporadicos',
        },
        {
          name: 'Unidade de negocios',
          pathTo: '/cadastro/unidade-de-negocios',
        },
        {
          name: 'Centro de custos',
          pathTo: '/cadastro/centro-de-custos',
        },
        {
          name: 'Representantes',
          pathTo: '/cadastro/representantes',
        },
        {
          name: 'Transportadoras',
          pathTo: '/cadastro/transportadoras',
        },
        {
          name: 'Fornecedores',
          pathTo: '/cadastro/fornecedores',
        },
        {
          name: 'Parceiros indicantes',
          pathTo: '/cadastro/parceiros-indicantes',
        },
        {
          name: 'Marketplaces',
          pathTo: '/cadastro/marketplaces',
        },
        {
          name: 'Ramos de atividade',
          pathTo: '/cadastro/ramos-de-atividade',
        },
        {
          name: 'Condições de pagamento',
          pathTo: '/cadastro/condicoes-de-pagamento',
        },
        {
          name: 'Administradora de cartões',
          pathTo: '/cadastro/administradora-de-cartoes',
        },
        {
          name: 'Classificações de receitas e despesas',
          pathTo: '/cadastro/classificacao-receitas-despesa',
        },
        {
          name: 'Plano de contas DRE',
          pathTo: '/cadastro/plano-de-conta-dre',
        },
        {
          name: 'Tabela de despesas',
          pathTo: '/cadastro/tabela-despesas',
        },
        {
          name: 'Tabela de receitas',
          pathTo: '/cadastro/tabela-receitas',
        },
        {
          name: 'Regiões',
          pathTo: '/cadastro/regioes',
        },
        {
          name: 'Cidades',
          pathTo: '/cadastro/cidades',
        },
        {
          name: 'Paises',
          pathTo: '/cadastro/paises',
        },
        {
          name: 'Cadastro de dolares',
          pathTo: '/cadastro/cadastro-de-dolares',
        },
        {
          name: 'Feriados',
          pathTo: '/cadastro/feriados',
        },
        {
          name: 'Bandeiras de cartões',
          pathTo: '/cadastro/bandeiras-de-cartoes',
        },
        {
          name: 'Contas de movimentação financeira',
          pathTo: '/cadastro/contas-de-movimentacao-financeira',
        },
      ],
    },
    {
      icon: 'fa-arrow-right-from-bracket',
      onClick: sair,
      name: 'Sair',
      title: 'Sair',
    },
  ] as IItems[]

  const devItems = [
    {
      icon: 'fa-duotone fa-chart-user',
      isActive: handleActive('demonstrativos'),
      name: 'Demonstrativos',
      title: 'Demonstrativos',
      subItems: [
        {
          name: 'Dashboards comerciais',
          pathTo: '/demonstrativos/comercial-dashboards',
        },
        {
          name: 'Top vendas',
          pathTo: '/demonstrativos/top-vendas',
        },
        {
          name: 'Comparativo anual de vendas',
          pathTo: '/demonstrativos/comparativo-de-vendas',
        },
        {
          name: 'Clientes histórico de compras',
          pathTo: '/demonstrativos/cliente-historico-compras',
        },
        {
          name: 'Cartões',
          pathTo: '/demonstrativos/cartoes',
        },
      ],
    },
    {
      icon: `fa-duotone fa-bullhorn`,
      isActive: handleActive('comercial/'),
      name: 'Comercial',
      title: 'Comercial',
      subItems: [
        {
          name: 'Operações comerciais',
          pathTo: '/comercial/operacoes',
        },
        {
          name: 'Fechamento de contas',
          pathTo: '/comercial/fechamentos-de-contas',
        },
        {
          name: 'Pedidos de compra',
          pathTo: '/comercial/pedidos-de-compra',
        },
        {
          name: 'Orçamentos não convertidos',
          pathTo: '/comercial/orcamentos-nao-convertidos',
        },
        {
          name: 'Follow-ups de operações',
          pathTo: '/comercial/followups-de-operacoes',
        },
      ],
    },
    {
      icon: 'fa-cash-register',
      isActive: handleActive('caixas'),
      name: 'Caixas',
      title: 'Caixa',
      subItems: [
        {
          name: 'Detalhamento',
          pathTo: '/caixas/detalhamento',
        },
        {
          name: 'Operações não recebidas',
          pathTo: '/caixas/operacoes-nao-recebidas',
        },
        {
          name: 'Fechamentos de caixa',
          pathTo: '/caixas/fechamentos-de-caixa',
        },
      ],
    },
    // {
    //   icon: 'fa-credit-card',
    //   isActive: handleActive('cartoes/'),
    //   name: 'Cartões',
    //   title: 'Cartões',
    //   subItems: []
    // },
    {
      icon: `fa-duotone fa-file-invoice-dollar`,
      isActive: handleActive('financeiro'),
      name: 'Financeiro',
      title: 'Financeiro',
      subItems: [
        {
          name: 'Duplicatas a receber',
          pathTo: '/financeiro/duplicatas-a-receber',
        },
        {
          name: 'Cheques recebidos',
          pathTo: '/financeiro/cheques-recebidos',
        },
        {
          name: 'Contas a pagar',
          pathTo: '/financeiro/contas-a-pagar ',
        },
        {
          name: 'Cartões',
          pathTo: '/financeiro/cartoes',
        },
      ],
    },
    {
      icon: `fa-duotone fa-bag-shopping`,
      isActive: handleActive('produtos'),
      name: 'Produtos',
      title: 'Produtos',
      subItems: [
        {
          name: 'Cadastro de produtos',
          pathTo: '/produtos/cadastro-de-produtos',
        },
      ],
    },
    {
      icon: `fa-duotone fa-file-lines`,
      isActive: handleActive('fiscal'),
      name: 'Fiscal',
      title: 'Fiscal',
      subItems: [
        {
          name: 'Notas fiscais',
          pathTo: '/fiscal/notas-fiscais',
        },
        {
          name: 'Cupons fiscais emitidos',
          pathTo: '/fiscal/cupons-fiscais-eletronicos-emitidos',
        },
      ],
    },
    {
      icon: `fa-duotone fa-folders`,
      isActive: handleActive('cadastro/'),
      name: 'Cadastro',
      title: 'Cadastro',
      subItems: [
        {
          name: 'Clientes constantes',
          pathTo: '/cadastro/clientes-constantes',
        },
        {
          name: 'Clientes esporádicos',
          pathTo: '/cadastro/clientes-esporadicos',
        },
        {
          name: 'Unidade de negocios',
          pathTo: '/cadastro/unidade-de-negocios',
        },
        {
          name: 'Centro de custos',
          pathTo: '/cadastro/centro-de-custos',
        },
        {
          name: 'Representantes',
          pathTo: '/cadastro/representantes',
        },
        {
          name: 'Transportadoras',
          pathTo: '/cadastro/transportadoras',
        },
        {
          name: 'Fornecedores',
          pathTo: '/cadastro/fornecedores',
        },
        {
          name: 'Parceiros indicantes',
          pathTo: '/cadastro/parceiros-indicantes',
        },
        {
          name: 'Marketplaces',
          pathTo: '/cadastro/marketplaces',
        },
        {
          name: 'Ramos de atividade',
          pathTo: '/cadastro/ramos-de-atividade',
        },
        {
          name: 'Condições de pagamento',
          pathTo: '/cadastro/condicoes-de-pagamento',
        },
        {
          name: 'Administradora de cartões',
          pathTo: '/cadastro/administradora-de-cartoes',
        },
        {
          name: 'Classificações de receitas e despesas',
          pathTo: '/cadastro/classificacao-receitas-despesa',
        },
        {
          name: 'Plano de contas DRE',
          pathTo: '/cadastro/plano-de-conta-dre',
        },
        {
          name: 'Tabela de despesas',
          pathTo: '/cadastro/tabela-despesas',
        },
        {
          name: 'Tabela de receitas',
          pathTo: '/cadastro/tabela-receitas',
        },
        {
          name: 'Regiões',
          pathTo: '/cadastro/regioes',
        },
        {
          name: 'Cidades',
          pathTo: '/cadastro/cidades',
        },
        {
          name: 'Paises',
          pathTo: '/cadastro/paises',
        },
        {
          name: 'Cadastro de dolares',
          pathTo: '/cadastro/cadastro-de-dolares',
        },
        {
          name: 'Feriados',
          pathTo: '/cadastro/feriados',
        },
        {
          name: 'Bandeiras de cartões',
          pathTo: '/cadastro/bandeiras-de-cartoes',
        },
        {
          name: 'Contas de movimentação financeira',
          pathTo: '/cadastro/contas-de-movimentacao-financeira',
        },
      ],
    },
    {
      icon: 'fa-arrow-right-from-bracket',
      onClick: sair,
      name: 'Sair',
      title: 'Sair',
    },
  ]

  return (
    <MaxSideBar
      items={isGestor ? itemsSideBarGestor : itemsSideBarCS}
      pathImage={logo}
      NavLink={NavLink}
    />
  )
}
