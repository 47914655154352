import { useCallback, useEffect, useState } from 'react'

import { notifyErrorCatch } from 'utils'
import api from 'api'

import { CartoesContext } from 'contexts/cartoesContext'
import { useContextSelector } from 'use-context-selector'

import { Select } from 'maxscalla-lib'
import { OptionsBandeira } from '@types'

interface ISelectOperacao {
  value: string | number | undefined
  setValue: (newValue: string) => void
}

export const SelectBandeira = ({ value, setValue }: ISelectOperacao) => {
  const [optionsBandeira, setOptionsBandeira] = useState<OptionsBandeira[]>([])
  const cartoes = useContextSelector(CartoesContext, (context) => context)

  const getOptions = useCallback(async () => {
    try {
      const params = {
        dataInicial: cartoes.filters.dataInicial,
        dataFinal: cartoes.filters.dataFinal,
      }

      const { data } = await api.getOptionsBandeiraCartoes(params)

      setOptionsBandeira(data)
    } catch (error) {
      notifyErrorCatch(error)
    }
  }, [cartoes.filters])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  return (
    <Select
      name="select-FCOorPED"
      value={String(value) || ''}
      focusColor={'blue'}
      onChange={(e: any) => setValue(e.target.value)}
    >
      <optgroup label="Bandeira" />
      <option hidden>Bandeira</option>
      {optionsBandeira.map((option) => (
        <option key={option.bandeiraId} value={option.bandeiraId}>
          {option.bandeira}
        </option>
      ))}
    </Select>
  )
}
