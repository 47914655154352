import { MaxCard } from 'maxscalla-lib'
import clsx from 'clsx'
import moment from 'moment'
import { Calender, Container } from './styles'
import { MainContext } from 'contexts/mainContext'
import { useContextSelector } from 'use-context-selector'

const filterMonths = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
]

interface MonthsCalenderProps {
  isOpen: boolean
}

export const MonthsCalender = ({ isOpen }: MonthsCalenderProps) => {
  const { dataSelecionada, handleChangeYear, changeMesSelecionado } =
    useContextSelector(MainContext, (context) => {
      return {
        dataSelecionada: context.dataSelecionada,
        handleChangeYear: context.handleChangeYear,
        changeMesSelecionado: context.changeMesSelecionado,
      }
    })

  const isSelectedMonth = (month: string) =>
    moment(dataSelecionada).format('MMM') === month.toLocaleLowerCase()

  return (
    <Container style={{ display: isOpen ? 'inherit' : 'none' }}>
      <MaxCard.Container style={{ boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
        <MaxCard.Body id="months-calender" style={{ padding: 15 }}>
          <div className="d-flex flex-column align-items-center">
            <div
              className="d-flex flex-row gap-30-px align-items-center"
              style={{
                paddingInline: '16px',
                height: '36px',
              }}
            >
              <button type="button" onClick={() => handleChangeYear('sub')}>
                <i className="fa-solid fa-chevron-left fs-12 cursor-pointer" />
              </button>
              <span className="fw-bold">
                {moment(dataSelecionada).format('YYYY')}
              </span>
              <button type="button" onClick={() => handleChangeYear('add')}>
                <i className="fa-solid fa-chevron-right fs-12 cursor-pointer" />
              </button>
            </div>
            <Calender className="d-flex flex-column gap-15-px">
              <div className="months">
                {filterMonths.map((month, i) => (
                  <div
                    key={month}
                    className={clsx(
                      isSelectedMonth(month) && 'active',
                      'd-flex justify-content-center fs-6',
                    )}
                    onClick={() =>
                      changeMesSelecionado(
                        moment(`${i + 1}`, 'MM').format('MM'),
                      )
                    }
                  >
                    {month}
                  </div>
                ))}
              </div>
            </Calender>
          </div>
        </MaxCard.Body>
      </MaxCard.Container>
    </Container>
  )
}
