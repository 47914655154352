export const TableLoading = () => {
  return (
    <tr>
      <div>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            margin: 'auto',
          }}
          className="spinner-border"
          role="status"
        />
      </div>
      <div />
    </tr>
  )
}
