import { useContext } from 'react'
import { ContextLogin, StateLoginContext } from '../contexts/loginContext'

const useAuthentication = (): StateLoginContext => {
  const context = useContext(ContextLogin)

  return context
}

export default useAuthentication
