import styled from 'styled-components'

export const Ranges = styled.div.attrs({
  className: 'ranges',
})`
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;

  @media (max-width: 420px) {
    width: 150px !important;
    position: fixed !important;
    right: 15px;
  }
`
