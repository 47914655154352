import moment from 'moment'

export function getDateFromRange(
  dataInicial: string,
  dataFinal: string,
  year?: boolean,
) {
  const endDate = moment(dataFinal)
  const dates = []

  let compareDate = moment(dataInicial)

  while (compareDate.isBefore(endDate)) {
    if (year) {
      dates.push(compareDate.format('YYYY'))
      compareDate = compareDate.add(1, 'year')
    } else {
      dates.push(compareDate.format('YYYYMM'))
      compareDate = compareDate.add(1, 'month')
    }
  }

  if (year) {
    return dates
  } else {
    return dates.reverse()
  }
}
