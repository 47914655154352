/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import clsx from 'clsx'

import { useContextSelector } from 'use-context-selector'
import { MainContext } from 'contexts/mainContext'

import { IDayValues } from '../@types'
import { maskMoney } from '../utils'

import { Anchor } from '.'
import { MovimentoVendasContext } from 'contexts/movimentoVendasContext'

const diasSemana = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb']

interface Props {
  data: string | undefined
  dataSelecionada: string
  goToOperacoes: () => void
}

const Calendario = ({
  data,
  dataSelecionada,
  goToOperacoes,
}: Props): ReactElement => {
  const { filters, changeDataSelecionada } = useContextSelector(
    MainContext,
    (context) => {
      return {
        filters: context.filters,
        changeDataSelecionada: context.changeDataSelecionada,
      }
    },
  )

  const { dayValues, diasNaoUteis, total } = useContextSelector(
    MovimentoVendasContext,
    (context) => {
      return context.movimentoVendas
    },
  )

  const [daySelected, setDaySelected] = useState<number>(() => {
    const hoje = Number(moment(dataSelecionada).format('DD'))

    return hoje
  })
  const [diasMes, setDiasMes] = useState<number[][]>([])
  const [porcentagens, setPorcentagens] = useState<number[][]>([])
  const [valoresTooltip, setValoresTooltip] = useState<number[][]>([])
  const [multiplicador, setMultiplicador] = useState<number>(1)

  const navigate = useNavigate()

  const buscarTotalizadorPeloDia = (dia: number): IDayValues | null => {
    let totalizador = null

    dayValues.map((tot) => {
      const diaTotalizador = Number(moment(tot.data).format('DD'))

      if (dia === diaTotalizador) {
        totalizador = tot
      }
    })

    return totalizador
  }

  const handleMultiplicador = useCallback(() => {
    const mesAtual = Number(moment().format('MM'))
    const mesFiltro = Number(moment(data).format('MM'))

    if (mesFiltro !== mesAtual) {
      setMultiplicador(5)
      return
    }

    const diaAtual = Number(moment().format('DD'))

    if (diaAtual <= 7) {
      setMultiplicador(2)
      return
    }

    if (diaAtual <= 14) {
      setMultiplicador(3)
      return
    }

    if (diaAtual <= 21) {
      setMultiplicador(4)
      return
    }

    setMultiplicador(5)
  }, [data])

  const handleChangeData = (dia: number) => {
    const mes = moment(data).format('MM')
    const ano = moment(data).format('YYYY')

    const dataFormatada = moment(`${ano}-${mes}-${dia}`).format('YYYY-MM-DD')

    changeDataSelecionada(dataFormatada)
    setDaySelected(dia)
  }

  const handleDayColor = (dia: number, value: number): string => {
    const selectedMonthYear = moment(data || '').format('YYYY/MM')
    const currentMonthYear = moment().format('YYYY/MM')
    const currentDate = new Date()
    const currentDay = currentDate.getDate()

    if (diasNaoUteis?.find((e) => new Date(e.cfinutpan).getDate() === dia)) {
      return '#ececec'
    }
    if (selectedMonthYear === currentMonthYear && dia > currentDay) {
      return '#ececec'
    }
    if (!value) return '#ececec'
    return ''
  }

  const handleNumeroDiasMes = useCallback(() => {
    const mesSelecionado = moment(data).startOf('month')

    const numeroDiasMes = moment(data).daysInMonth()

    let dias: number[][] = []
    let porcentagensPorDia: number[][] = []
    let valoresEntrada: number[][] = []

    let dia = 0

    for (let i = 0; i < 6; i += 1) {
      let rowDias: number[] = []
      let rowPorcentagens: number[] = []
      let rowValoresEntrada: number[] = []
      let posInicial = 0

      if (i === 0) {
        const primeiroDiaMes = mesSelecionado.format('ddd')
        posInicial = diasSemana.indexOf(primeiroDiaMes)
        rowDias = new Array(posInicial).fill(null).map(() => 0)
      }

      for (let j = posInicial; j < 7; j += 1) {
        const totalizador = buscarTotalizadorPeloDia(dia + 1)

        if (dia === numeroDiasMes) {
          rowDias[j] = 0
          rowPorcentagens[j] = 0
          rowValoresEntrada[j] = 0
        } else {
          rowDias[j] = dia + 1
          rowPorcentagens[j] = totalizador
            ? (Number(totalizador.entrada) / Number(total || 0)) * 100
            : 0
          rowValoresEntrada[j] = Number(totalizador?.entrada)
          dia += 1
        }
      }

      porcentagensPorDia[i] = rowPorcentagens
      rowPorcentagens = []

      valoresEntrada[i] = rowValoresEntrada
      rowValoresEntrada = []

      dias[i] = rowDias
      rowDias = []
    }

    setPorcentagens(porcentagensPorDia)
    setValoresTooltip(valoresEntrada)
    setDiasMes(dias)
  }, [data, total, dayValues])

  useEffect(() => {
    handleMultiplicador()
  }, [handleMultiplicador])

  useEffect(() => {
    handleNumeroDiasMes()
  }, [handleNumeroDiasMes])

  return (
    <div id="calendario" className="flex-1 x-scroll">
      <div className="min-w-350px">
        <div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                gap: 10,
                marginBottom: 10,
              }}
            >
              {diasSemana.map((dia) => (
                <span key={dia} style={{ textAlign: 'center' }}>
                  {String(dia).toUpperCase()}
                </span>
              ))}
            </div>
            {diasMes.map((dias, row) => (
              <div
                key={row}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                  gap: 5,
                }}
              >
                {dias.map((dia, col) => {
                  if (dia === 0) {
                    return (
                      <Anchor
                        key={col}
                        className="calendario-day-box w-100 p-0 m-0"
                        style={{
                          backgroundColor: '#fff',
                          cursor: 'default',
                        }}
                      />
                    )
                  } else {
                    return (
                      <Anchor
                        style={{
                          backgroundColor: handleDayColor(
                            dia,
                            valoresTooltip[row][col],
                          ),
                        }}
                        key={col}
                        className={`tooltip-custom pointer calendario-day-box w-100 ${
                          dia === daySelected
                            ? 'calendario-day-box-selected'
                            : ''
                        }`}
                        onClick={() => handleChangeData(dia)}
                        data-title={maskMoney(valoresTooltip[row][col])}
                        onDoubleClick={goToOperacoes}
                      >
                        <div
                          className="calendario-coluna"
                          style={{
                            height: `${
                              porcentagens[row][col] * multiplicador
                            }%`,
                            maxHeight: '100%',
                          }}
                        />
                        <div className="d-flex flex-column align-items-end justify-content-between">
                          <span
                            className={clsx(
                              'calendario-dia',
                              // dia === daySelected && 'cs-color-white',
                            )}
                          >
                            {dia}
                          </span>
                          <span
                            className={clsx(
                              'calendario-dia fw-light',
                              // dia === daySelected && 'cs-color-white',
                            )}
                          >
                            {String(diasSemana[col]).toLowerCase()}
                          </span>
                        </div>
                      </Anchor>
                    )
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendario

Calendario.defaultProps = {
  col: 12,
  title: '',
  diasNaoUteis: [],
}
