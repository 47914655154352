export const selectRow = (rowNumber: number) => {
  const rowsSelected = $('.selected-row')

  if (rowsSelected.length) {
    rowsSelected.removeClass('selected-row')
  }

  const id = `row-${rowNumber}`

  const rowToSelect = $(`#${id}`)

  rowToSelect.trigger('focus')
  rowToSelect.addClass('selected-row')
}
